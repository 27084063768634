h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: var(--bold);
    line-height: 1.2;
}

h1 {
    font-size: var(--header1);
}

h2 {
    font-size: var(--header2);
}

h3 {
    font-size: var(--header3);
}

h4 {
    font-size: var(--header4);
}

h5 {
    font-size: var(--header5);
}

h6 {
    font-size: var(--header6);
}

p {
    color: var(--lightText);
    font-size: var(--default);
}

a {
    color: var(--lightText);
    font-size: var(--link);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
