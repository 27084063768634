.content {
    background-color: var(--white, #fff);

    &__container {
        max-width: 104rem;
        padding: 4.8rem 1.2rem 1.2rem;
        margin: 0 auto;

        @media (max-width: $break-tablet) {
            padding: 3.2rem 1.2rem 1.2rem;
        }
    }

    &__title {
        font-family: ivyjournal, sans-serif;
        font-size: 3.8rem;
        margin: 0 0 1.2rem;
        text-align: center;
        color: var(--neutral900);
        font-weight: var(--regular);

        @media (max-width: $break-tablet) {
            font-size: 2rem;
        }
    }

    &__body {
        text-align: center;
        color: var(--neutral900);

        p {
            color: var(--neutral900);
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.6rem;
        }
    }
}
