.tag {
    align-items: center;
    background-color: var(--accentBlue);
    border-radius: 3rem;
    color: var(--white);
    display: flex;
    font-size: var(--link);
    font-weight: var(--bold);
    line-height: 1;
    margin-top: 1.2rem;
    padding: 0.3rem 0.4rem;
    z-index: $level-one;

    svg {
        fill: var(--white);
        height: 1.2rem;
        margin-right: 0.4rem;
        width: 1.2rem;
    }

    &--new-episode {
        background-color: var(--accentRedDark);
        border-radius: 4rem;
        left: 1.2rem;
        margin: 0;
        padding: 0.4rem 0.8rem;
        position: absolute;
        top: 1.2rem;
    }
}
