@charset "UTF-8";
/* stylelint-disable-next-line comment-empty-line-before */
/* SASS MODULES
========================================================================== */
/* SETTINGS
========================================================================== */
/* Breakpoints */
/* Z-Index Layers */
/* Transitions */
/*
 * Adds hover and focus styles for tablet (by default) and up
 * Only keeps focus styles for below tablet (by default)
 * If focus set to false then only hover styling will be applied
 */
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
  https://github.com/bfred-it/object-fit-images
*/
/**
 * This mixin can be used to define a line clamp limit
 * @type {Number}
 */
/**
 * This mixin can be used to add skeleton loading background
 */
/*
 * Applies an ellipsis to text that overflows the width passed into the mixin
 * NB this ONLY works on single lines of text
 */
.theme-mood-ziva {
  --mood-primary: #ae2375;
  --mood-secondary: var(--white, #fff);
}
.theme-mood-zilara {
  --mood-primary: #8358A7;
  --mood-secondary: var(--white, #fff);
}
.theme-mood-zoetry {
  --mood-primary: #018299;
  --mood-secondary: var(--white, #fff);
}
.theme-mood-breathless {
  --mood-primary: #8643B2;
  --mood-secondary: var(--white, #fff);
}
.theme-mood-dreams {
  --mood-primary: #cfac7c;
  --mood-secondary: var(--neutral900, #141d38);
}
.theme-mood-vivid {
  --mood-primary: #1b806d;
  --mood-secondary: var(--white, #fff);
}
.theme-mood-alua {
  --mood-primary: #00a4af;
  --mood-secondary: var(--white, #fff);
}
.theme-mood-sunscape {
  --mood-primary: #ff8200;
  --mood-secondary: var(--neutral900, #141d38);
}

/* LAYOUT
========================================================================== */
/* GLOBAL
========================================================================== */
/*
---- If Font is served through local files the following mixins can be used ----
*/
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Medium.woff") format("woff"), url("../fonts/CentraNo2-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Extrabold.woff") format("woff"), url("../fonts/CentraNo2-Extrabold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Book.woff") format("woff"), url("../fonts/CentraNo2-Book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Bold.woff") format("woff"), url("../fonts/CentraNo2-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: CentraNo2;
  src: url("../fonts/CentraNo2-Light.woff") format("woff"), url("../fonts/CentraNo2-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
html {
  max-width: 100%;
  font-size: 62.5%;
  height: 100%;
}

body {
  --white: #fff;
  --black: #000;
  --logoText: #fff;
  --neutral50: #f8f5f4;
  --neutral100: #f0ebe8;
  --neutral200: #d7d4d2;
  --neutral300: #b9b6b8;
  --neutral400: #94949e;
  --neutral500: #727483;
  --neutral600: #575b6e;
  --neutral700: #343b53;
  --neutral800: #202843;
  --neutral900: #141d38;
  --neutral1000: #000b26;
  --neutral900light: #141d38;
  --accentRed: #eb5433;
  --accentRedDark: #ca2e2b;
  --accentGreen: #0d7066;
  --accentGreenDark: #075758;
  --accentBlue: #2950b8;
  --accentBlueLight: #1668e3;
  --accentBlueDark: #0d4eaf;
  --rgbaNeutral800: 32, 40, 67;
  --rgbaNeutral900: 22, 26, 39;
  --primary: var(--neutral800);
  --primaryDark: var(--neutral1000);
  --primaryDarker: var(--neutral900);
  --secondary: #fddb32;
  --secondaryDark: #ffb64c;
  --secondaryDarker: #daa52c;
  --tertiary: #56dec3;
  --text: var(--neutral50);
  --lightText: #f8f5f4;
  --button: var(--accentBlueLight);
  --buttonDisabled: var(--neutral400);
  --buttonHovered: var(--secondaryDarker);
  --navItemHovered: #9A9DAC;
  --socialButton: var(--lightText);
  --imageBackground: #161a27;
  --sliderBar: #ffc94c;
  --seperator-line: #dad6d6;
  --vjsSelectedText: #202843;
  --navigationTopBackground: #202843;
  --yellow: #ffc94c;
  --yellowDark: #ffba19;
  --rgbaSecondary: 255, 201, 76;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 700;
  --extra-bold: 800;
  --aspect16-10: 62.5%;
  --aspect16-9: 56.25%;
  --aspect1-1: 100%;
  --widget-width: 142.4rem;
  --widget-width-narrow: 71.2rem;
  --widget-padding-mobile: 1.2rem;
  --widget-padding-desktop: 3.2rem;
  --nav-height-desktop-sticky: 5.2rem;
  --nav-height-desktop: 8.8rem;
  --nav-top-height: 4.8rem;
  --nav-height-mobile: 6.8rem;
  --header-height-mobile: calc(var(--nav-height-mobile) + var(--nav-top-height));
  --header-height-desktop: calc(var(--nav-height-desktop) + var(--nav-top-height));
  --radius-mobile: 14.6rem;
  --radius-desktop: 24rem;
  --colorPlaceholder: rgb(101 101 101 / 40%);
  --cardShadow: .2rem .2rem 1.2rem rgb(0 11 38 / 10%);
  --banner-gradient: linear-gradient(180deg,
          rgb(22 26 39 / 0%) 9.57%,
          rgb(22 26 39 / 90%) 100%);
  --popupGradient: linear-gradient(180deg,
          rgb(22 26 39 / 0%) 15.57%,
          rgb(22 26 39 / 88%) 63.85%,
          var(--imageBackground) 100%);
  --controlsGradientDark: linear-gradient(270deg,
          rgb(32 40 67 / 70%) 0%,
          rgb(32 40 67 / 70%) 100%);
  --controlsGradientLight: linear-gradient(270deg,
          rgb(248 245 244 / 70%) 0%,
          rgb(248 245 244 / 70%) 100%);
  --heroChannelGradientLight: linear-gradient(180deg,
          rgb(248 245 244 / 0.01%) 39.92%,
          rgb(248 245 244 / 80%) 72.52%,
          #f8f5f4 93.92%);
  --heroChannelGradientDark: linear-gradient(to bottom,
          rgb(32 40 67 / 0%) 40%,
          var(--neutral800) 100%);
  --spacing-xxs: 0.4rem;
  --spacing-xs: 0.8rem;
  --spacing-s: 1.2rem;
  --spacing-m: 1.6rem;
  --spacing-l: 2.4rem;
  --spacing-xl: 3.2rem;
  --spacing-xxl: 6.4rem;
  --spacing-xxxl: 12.8rem;
  --default: 1.6rem;
  --large: 1.8rem;
  --header1: 4rem;
  --header2: 3.2rem;
  --header3: 2.5rem;
  --header4: 2rem;
  --header5: 1.8rem;
  --header6: var(--default);
  --meta: 1.4rem;
  --key: 1.3rem;
  --link: 1.2rem;
  --small: 1.1rem;
  --tiny: 1rem;
  color: var(--text);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: CentraNo2, helvetica, sans-serif;
  font-size: var(--default);
  overflow-x: hidden;
}
body[data-theme=light] {
  --logoText: #191e3b;
  --neutral50: #202843;
  --neutral600: #d7d4d2;
  --neutral700: var(--neutral600);
  --neutral900: #f8f5f4;
  --neutral1000: var(--neutral900);
  --primary: var(--neutral900);
  --secondary: var(--neutral800);
  --button: var(--accentBlueLight);
  --buttonHovered: var(--primaryDarker);
  --socialButton: var(--button);
  --sliderBar: var(--white);
  --vjsSelectedText: var(--white);
  --mapContentCard: var(--neutral1000);
  --navigationTopBackground: #f0ebe8;
  --cardShadow: 0 2px 4px rgb(0 11 38 / 20%);
  --popup-gradient: linear-gradient(180deg,
          rgb(255 255 255 / 0%) 15.57%,
          rgb(255 255 255 / 88%) 63.85%,
          var(--neutral1000) 100%);
}
body[data-enlarge=true] {
  --default: 2rem;
  --large: 2.4rem;
  --header1: 6.4rem;
  --header2: 4.8rem;
  --header3: 3.6rem;
  --header4: 2.8rem;
  --header5: 2.4rem;
  --meta: 1.8rem;
  --key: 1.6rem;
  --link: var(--meta);
  --small: 1.4rem;
  --tiny: 1.3rem;
}
body[data-enlarge=true] a,
body[data-enlarge=true] a * {
  text-decoration: underline !important;
}
body.is-homepage {
  background-color: var(--primaryDark);
}

.body-content {
  flex: 1 0 auto;
  overflow-x: hidden;
  padding-top: var(--nav-height-mobile);
}
@media (min-width: 840px) {
  .body-content {
    padding-top: var(--nav-height-desktop);
  }
}

.skeleton-background {
  background-color: var(--neutral700);
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 60%) var(--neutral700);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s skeleton-loading ease-in-out infinite;
}

button {
  color: inherit;
  cursor: pointer;
  border: none;
  background: inherit;
  font-family: inherit;
}

ul {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

svg {
  fill: var(--text);
}

/* Remove Autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: inherit;
  box-shadow: 0 0 0 1000px white inset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--bold);
  line-height: 1.2;
}

h1 {
  font-size: var(--header1);
}

h2 {
  font-size: var(--header2);
}

h3 {
  font-size: var(--header3);
}

h4 {
  font-size: var(--header4);
}

h5 {
  font-size: var(--header5);
}

h6 {
  font-size: var(--header6);
}

p {
  color: var(--lightText);
  font-size: var(--default);
}

a {
  color: var(--lightText);
  font-size: var(--link);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.fade-in-on-load {
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0, 0.4, 0.5, 1);
}
.fade-in-on-load.is-loaded {
  opacity: 1;
}

@keyframes skeleton-loading {
  to {
    background-position-x: -20%;
  }
}
@keyframes slide-in {
  0% {
    transform: translate(100%);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes video-intro {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes dot-mobile {
  0% {
    transform: scale(0.4);
  }
  50% {
    box-shadow: 0 0 9px 9px var(--tertiary);
    transform: scale(1);
  }
  100% {
    transform: scale(0.4);
  }
}
@keyframes dot-desktop {
  0% {
    transform: scale(0.7);
  }
  50% {
    box-shadow: 0 0 9px 9px var(--tertiary);
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}
/* COMPONENTS
========================================================================== */
/* stylelint-disable selector-max-specificity */
.video-js .vjs-overlay {
  display: none;
}

.bc-player-default_default .vjs-big-play-button {
  margin: 0;
  width: 5.6rem;
  height: 5.6rem;
  background-color: var(--button);
  transform: translate(-50%, -50%);
}

.bc-player-default_default:hover .vjs-big-play-button {
  background-color: var(--neutral50);
}

.video-js .vjs-big-play-button .vjs-icon-placeholder::before {
  font-size: 3.6rem;
  line-height: 6;
  color: var(--primaryDark);
}

.video-jjs .vjs-slider,
.video-js .vjs-load-progress {
  background-color: #202843;
}

.video-js .vjs-load-progress div {
  background-color: rgba(87, 91, 110, 0.4);
}

.video-js .vjs-play-progress.vjs-slider-bar {
  background-color: var(--sliderBar);
}

.bc-player-default_default .vjs-control-bar {
  background-color: #141d38;
}

.bc-player-default_default .vjs-playlist-endscreen-control {
  display: none;
}

.bc-player-default_default .vjs-time-control.vjs-time-divider {
  padding: 0;
  width: auto;
  min-width: auto;
  font-size: 1.3rem;
  line-height: 3;
}

.bc-player-default_default .vjs-time-control .vjs-current-time-display,
.bc-player-default_default .vjs-time-control .vjs-duration-display {
  font-size: 1.3rem;
}

.video-js .vjs-play-control,
.video-js .vjs-mute-control,
.video-js .vjs-playback-rate,
.video-js .vjs-fullscreen-control,
.video-js .vjs-playlist-endscreen-control {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.video-js .vjs-playback-rate {
  padding: 0 3rem;
}

.video-js.vjs-layout-small .vjs-playback-rate,
.video-js.vjs-layout-small .vjs-quality-menu-wrapper {
  display: block !important;
}

.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item {
  background-color: #202843;
}

.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item.vjs-selected {
  background-color: var(--button);
  color: var(--vjsSelectedText);
}
.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item.vjs-selected:focus {
  background-color: var(--vjsSelectedText);
  color: var(--button);
}
@media (min-width: 840px) {
  .video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item.vjs-selected:hover {
    background-color: var(--vjsSelectedText);
    color: var(--button);
  }
}

.video-js.bc-player-default_default .vjs-menu-item.vjs-menu-item:hover {
  background-color: #141d38;
}

.video-js .vjs-volume-level {
  background-color: var(--button);
}

.bc-player-default_default .vjs-menu-button-popup .vjs-menu .vjs-menu-item:active,
.bc-player-default_default .vjs-menu-button-popup .vjs-menu .vjs-menu-item:focus,
.bc-player-default_default .vjs-menu-button-popup .vjs-menu .vjs-menu-item:hover {
  text-shadow: none;
}

.bc-player-default_default .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  z-index: 10;
}

.video-js .vjs-playlist-endscreen {
  display: none;
}

.button {
  align-items: center;
  background-color: var(--button);
  border-radius: 5rem;
  /* so we can animate on hover */
  border: 0.1rem solid transparent;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: var(--default);
  font-weight: var(--bold);
  justify-content: center;
  line-height: 1;
  padding: 1.1rem 1.4rem;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.button svg {
  fill: currentcolor;
  flex-shrink: 0;
}
.button:focus {
  border-color: var(--button);
  background-color: var(--accentBlueDark);
  color: var(--button);
  text-decoration: none;
}
.button:focus svg {
  fill: currentcolor;
}
@media (min-width: 840px) {
  .button:hover {
    border-color: var(--button);
    background-color: var(--accentBlueDark);
    color: var(--button);
    text-decoration: none;
  }
  .button:hover svg {
    fill: currentcolor;
  }
}
.button--transparent {
  background-color: transparent;
  border-color: var(--neutral800);
  border-radius: 5rem;
  color: var(--neutral800);
  font-weight: var(--regular);
  min-width: 22rem;
  padding: 2rem 3.2rem;
}
.button--transparent:focus {
  border-color: currentcolor;
  background-color: transparent;
  color: currentcolor;
}
@media (min-width: 840px) {
  .button--transparent:hover {
    border-color: currentcolor;
    background-color: transparent;
    color: currentcolor;
  }
}
@media (min-width: 840px) {
  .button--transparent {
    font-size: var(--large);
    min-width: 22.4rem;
    padding: 1.8rem 3.2rem;
  }
}
.button--bold {
  font-weight: 700;
}
.button--bold:focus {
  color: var(--neutral800);
}
@media (min-width: 840px) {
  .button--bold:hover {
    color: var(--neutral800);
  }
}
.button--white {
  border: 0.138rem solid var(--white);
  text-transform: uppercase;
  color: var(--white);
}
.button--white:hover {
  background-color: transparent;
  color: currentcolor;
  border-color: currentcolor;
}

.linked-list {
  all: unset;
}
@media (min-width: 840px) {
  .linked-list {
    display: flex;
  }
}

.meta-data {
  display: flex;
  flex-wrap: wrap;
  font-weight: var(--light);
  transition: opacity 0.5s ease-in-out 0.5s;
}
.meta-data__item {
  align-items: center;
  color: var(--neutral300);
  display: flex;
  font-size: var(--meta);
  font-weight: var(--light);
  margin-bottom: 0.5rem;
  margin-right: 1.2rem;
}
.meta-data__item svg {
  margin-right: 0.4rem;
  fill: currentcolor;
  height: 1.6rem;
  width: 1.6rem;
}
.meta-data__item::before {
  background-color: var(--neutral300);
  border-radius: 100%;
  content: "";
  display: inline-block;
  height: 0.4rem;
  margin-right: 1.2rem;
  width: 0.4rem;
}
.meta-data__item:first-child::before {
  content: none;
}
.meta-data__progress-bar-container {
  background-color: var(--neutral600);
  position: relative;
  height: 0.4rem;
  width: 7rem;
}
.meta-data__progress-bar {
  background-color: var(--button);
  inset: 0 auto 0 0;
  position: absolute;
}
.meta-data--inline-video {
  margin: 2.4rem 0 0;
}
.meta-data--season {
  position: relative;
  margin: 0 0 2.4rem;
}
.meta-data--season .meta-data__item {
  color: var(--white);
}
.meta-data--series-promo {
  margin-top: 2.4rem;
}
.meta-data--series-promo .meta-data__item {
  color: var(--neutral300);
}
body[data-theme=light] .meta-data--series-promo .meta-data__item {
  color: var(--text);
}
.meta-data--channel-hero {
  margin: 2.4rem 0;
}
.meta-data--channel-hero .meta-data__item {
  color: var(--neutral300);
}
@media (min-width: 375px) {
  .meta-data__progress-bar-container {
    width: 10rem;
  }
}
@media (min-width: 1024px) {
  .meta-data__progress-bar-container {
    width: 14rem;
  }
  .meta-data--series-promo .meta-data__item {
    color: var(--white);
  }
  body[data-theme=light] .meta-data--series-promo .meta-data__item {
    color: var(--white);
  }
}

.object-fit-cover-picture__img {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

.static-hero {
  background-color: var(--colorPlaceholder);
  align-items: flex-end;
  display: flex;
  height: 39rem;
  justify-content: center;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.static-hero::after {
  background: linear-gradient(180deg, rgba(32, 40, 67, 0) 10%, var(--neutral800) 100%);
  content: "";
  inset: 0;
  position: absolute;
}
.static-hero--shareable {
  height: 47rem;
  margin-bottom: 3.2rem;
}
.static-hero__content {
  padding-bottom: 5rem;
  position: relative;
  text-align: center;
  z-index: 10;
}
.static-hero__logo {
  margin-bottom: 2rem;
  max-height: 12rem;
}
.static-hero__description {
  font-weight: var(--light);
  line-height: 1.2;
  margin: 0 auto;
  max-width: 35rem;
  padding: 0 1.2rem;
}
body[data-theme=light] .static-hero__title {
  color: var(--lightText);
}
body[data-theme=light] .static-hero--shareable::after {
  background: var(--heroChannelGradientLight);
}
@media (min-width: 425px) {
  .static-hero__description {
    max-width: 50rem;
  }
  .static-hero__logo {
    max-height: 15rem;
  }
}
@media (min-width: 840px) {
  .static-hero {
    height: 40rem;
  }
  .static-hero--shareable {
    height: 47rem;
  }
  .static-hero__description {
    max-width: 67rem;
  }
}

.tag {
  align-items: center;
  background-color: var(--accentBlue);
  border-radius: 3rem;
  color: var(--white);
  display: flex;
  font-size: var(--link);
  font-weight: var(--bold);
  line-height: 1;
  margin-top: 1.2rem;
  padding: 0.3rem 0.4rem;
  z-index: 10;
}
.tag svg {
  fill: var(--white);
  height: 1.2rem;
  margin-right: 0.4rem;
  width: 1.2rem;
}
.tag--new-episode {
  background-color: var(--accentRedDark);
  border-radius: 4rem;
  left: 1.2rem;
  margin: 0;
  padding: 0.4rem 0.8rem;
  position: absolute;
  top: 1.2rem;
}

.video-play-cta {
  display: flex;
  align-items: center;
  margin: 0.8rem 0;
  word-break: break-word;
}
.video-play-cta__button {
  align-items: center;
  background-color: var(--button);
  border-radius: 100%;
  border: 0.1rem solid transparent;
  cursor: pointer;
  display: flex;
  height: 5.6rem;
  justify-content: center;
  transition: transform 0.5s ease-in-out, background-color 0.3s;
  z-index: 2;
  width: 5.6rem;
}
.video-play-cta__button:focus {
  background-color: rgb(var(--rgbaNeutral800) 0.7);
  border-color: var(--button);
}
.video-play-cta__button:focus svg {
  fill: var(--button);
}
@media (min-width: 840px) {
  .video-play-cta__button:hover {
    background-color: rgb(var(--rgbaNeutral800) 0.7);
    border-color: var(--button);
  }
  .video-play-cta__button:hover svg {
    fill: var(--button);
  }
}
.video-play-cta__button svg {
  fill: var(--primaryDark);
  height: 4rem;
  width: 4rem;
}
.video-play-cta__description {
  padding-left: 1.6rem;
}
.video-play-cta__progress {
  color: var(--secondary);
  font-size: var(--meta);
  font-weight: var(--light);
}
body[data-theme=light] .video-play-cta__progress {
  color: var(--white);
}
.video-play-cta__title {
  color: var(--secondary);
  font-weight: var(--bold);
  line-height: 1.2;
  margin: 0;
}
body[data-theme=light] .video-play-cta__title {
  color: var(--white);
}
@media (min-width: 1024px) {
  .video-play-cta {
    margin: 2.4rem 0;
  }
}

.widget-header__subtitle {
  font-size: var(--default);
  font-weight: var(--bold);
  padding: 0 var(--widget-padding-mobile);
  margin-top: 0;
}
.widget-header__title {
  color: var(--text);
  padding: 0 var(--widget-padding-mobile);
  line-height: 1.2;
  margin-top: 0.2rem;
}
.widget-header__promo-rail .widget-header__subtitle,
.widget-header__promo-rail .widget-header__title {
  padding: 0 var(--widget-padding-mobile);
}
@media (min-width: 840px) {
  .widget-header__promo-rail .widget-header__subtitle,
  .widget-header__promo-rail .widget-header__title {
    padding: 0 var(--widget-padding-desktop);
  }
}
@media (min-width: 1200px) {
  .widget-header__subtitle {
    padding: 0;
  }
  .widget-header__title {
    margin-bottom: 1.5rem;
    padding: 0;
  }
}

.widget {
  margin: 2.4rem auto 0;
  max-width: var(--widget-width);
  position: relative;
  padding: 0;
}
.widget__list {
  display: flex;
  margin: 0.8rem -0.6rem 0;
  padding: 0 1.2rem 1rem;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
}
.widget--narrow {
  max-width: var(--widget-width-narrow);
  overflow: hidden;
}
@media (min-width: 1200px) {
  .widget {
    margin-top: 4.8rem;
    padding: 0 var(--widget-padding-desktop);
  }
  .widget__list {
    scroll-snap-type: none;
    transition: transform 0.6s ease-in-out;
    margin: 0 -1.2rem;
    padding: 0;
    position: relative;
    overflow: initial;
  }
  .widget--narrow .widget__list-wrapper {
    overflow: hidden;
  }
  .widget--narrow .widget__list {
    margin: 0 -0.6rem;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: var(--widget-width);
  position: relative;
  padding: var(--spacing-l) var(--spacing-s);
}
@media (min-width: 840px) {
  .wrapper {
    padding: var(--spacing-xxl) var(--spacing-l);
  }
}

/* WIDGETS
========================================================================== */
.error-404 {
  color: var(--neutral800);
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100lvh;
  justify-content: center;
  margin-top: calc(-1 * var(--nav-height-desktop));
}
.error-404__title {
  font-family: ivyjournal, sans-serif;
  font-weight: var(--regular);
  color: var(--neutral800);
  font-size: 6.4rem;
  margin: 0;
}
.error-404__description {
  color: var(--neutral800);
}
.error-404__link {
  margin-top: 4.8rem;
  font-weight: 700;
}
@media (max-width: 840px) {
  .error-404__title {
    font-size: 4rem;
  }
  .error-404__link {
    margin-top: 3.2rem;
  }
}

.content {
  background-color: var(--white, #fff);
}
.content__container {
  max-width: 104rem;
  padding: 4.8rem 1.2rem 1.2rem;
  margin: 0 auto;
}
@media (max-width: 840px) {
  .content__container {
    padding: 3.2rem 1.2rem 1.2rem;
  }
}
.content__title {
  font-family: ivyjournal, sans-serif;
  font-size: 3.8rem;
  margin: 0 0 1.2rem;
  text-align: center;
  color: var(--neutral900);
  font-weight: var(--regular);
}
@media (max-width: 840px) {
  .content__title {
    font-size: 2rem;
  }
}
.content__body {
  text-align: center;
  color: var(--neutral900);
}
.content__body p {
  color: var(--neutral900);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
}

.cookie-banner {
  inset: 0;
  position: fixed;
  z-index: 300;
}
.cookie-banner__content {
  display: flex;
  align-items: flex-end;
  background-color: rgba(0, 11, 38, 0.8);
  box-shadow: 0 -10px 25px rgba(0, 0, 0, 0.8);
  inset: auto 0 0;
  max-width: calc(100vw - 5.6rem);
  padding: 4rem;
  position: fixed;
  z-index: 320;
  margin: 2.8rem auto;
  border-radius: 0.8rem;
}
@media (max-width: 840px) {
  .cookie-banner__content {
    margin: 0;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.cookie-banner__content-left {
  flex: 1 1;
}
.cookie-banner__title {
  font-family: ivyjournal, sans-serif;
  font-size: 3.8rem;
  font-weight: var(--regular);
  margin: 0;
  margin-bottom: 1.6rem;
}
@media (max-width: 840px) {
  .cookie-banner__title {
    font-size: 2rem;
  }
}
.cookie-banner__text {
  color: var(--text);
  max-width: 70%;
}
@media (max-width: 840px) {
  .cookie-banner__text {
    max-width: 100%;
    font-size: 1.4rem;
  }
}
.cookie-banner__link {
  font-size: 1.6rem;
  font-weight: 700;
}
.cookie-banner__accept-button {
  padding: 1.6rem 2.8rem;
  border: 0.1rem solid var(--white);
  border-radius: 5rem;
  transition: all 0.6s ease-in-out;
}
.cookie-banner__accept-button:hover {
  background-color: var(--white);
  color: var(--neutral800);
}

.featured-promo-rail {
  position: relative;
  background-color: var(--white, #fff);
  max-width: 100%;
}
.featured-promo-rail + section {
  position: relative;
}
.featured-promo-rail + section::before {
  content: "";
  width: 25%;
  display: block;
  margin: 0 auto;
  height: 0.1rem;
  background-color: var(--seperator-line);
}
.featured-promo-rail__list-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 144rem;
  margin: 0 auto;
}
.featured-promo-rail__list-wrapper:focus .featured-promo-rail-pagination {
  opacity: 1;
}
@media (min-width: 840px) {
  .featured-promo-rail__list-wrapper:hover .featured-promo-rail-pagination {
    opacity: 1;
  }
}
.featured-promo-rail__title {
  margin: 0;
  order: -1;
  text-align: center;
  color: var(--primary);
}
.featured-promo-rail__title::selection {
  background: transparent;
}
.featured-promo-rail__list {
  all: unset;
  display: flex;
  list-style: none;
  position: relative;
  transition: transform 0.6s ease-in-out;
  width: 58.9583333333%;
}
.featured-promo-rail__list-item {
  flex-shrink: 0;
  margin: 0 2.4rem 0 0;
  pointer-events: none;
  transition: opacity 0.25s cubic-bezier(0, 0.4, 0.5, 1);
  width: 100%;
  opacity: 0.4;
}
.featured-promo-rail__list-item:not(.is-active) .featured-promo-rail__item-description, .featured-promo-rail__list-item:not(.is-active) .featured-promo-rail__item-link {
  opacity: 0;
  transition: opacity 0.2s;
}
.featured-promo-rail__list-item.is-active {
  opacity: 1;
  pointer-events: unset;
}
.featured-promo-rail__card {
  display: flex;
  height: 60rem;
  position: relative;
}
.featured-promo-rail__image-container {
  background-color: var(--neutral100);
  border-radius: 0.8rem;
  flex-shrink: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 59.6341463415%;
}
.featured-promo-rail__content {
  width: 68.5365853659%;
  z-index: 10;
}
.featured-promo-rail__item-title {
  font-family: ivyjournal, sans-serif;
  font-size: 6.4rem;
  line-height: 100%;
  margin-top: 1.2rem;
  margin-bottom: var(--spacing-l);
  color: var(--primary);
  font-weight: 400;
}
.featured-promo-rail__item-description {
  width: 50%;
  font-size: 1.6rem;
  margin-bottom: 0;
  color: var(--primary);
}
.featured-promo-rail__item-link {
  width: fit-content;
  margin-top: 5.6rem;
  font-size: 1.6rem;
  padding: 1.2rem 1.6rem;
}
@media (max-width: 840px) {
  .featured-promo-rail .featured-promo-rail-pagination {
    display: flex;
    margin-left: auto;
    margin-right: initial;
    opacity: 1;
  }
  .featured-promo-rail__content {
    width: 100%;
    max-width: 30.7rem;
    z-index: 10;
  }
  .featured-promo-rail__pagination {
    margin-bottom: 3.2rem;
  }
  .featured-promo-rail__title {
    font-size: 2.5rem;
  }
  .featured-promo-rail__list {
    margin: 0 -0.6rem;
    padding: 0 0.6rem 1rem;
    width: 100%;
  }
  .featured-promo-rail__card {
    height: 40rem;
  }
  .featured-promo-rail__list-item {
    margin: 0 0.6rem;
    opacity: 1;
  }
  .featured-promo-rail__list-item:first-child {
    margin-left: 0;
  }
  .featured-promo-rail__item-title {
    font-size: 4rem;
  }
  .featured-promo-rail__item-description {
    width: 76%;
    padding-right: 0.8rem;
  }
  .featured-promo-rail__image-container {
    width: 47.0752089136%;
  }
}
@media (max-width: 640px) {
  .featured-promo-rail__card {
    height: auto;
  }
  .featured-promo-rail__item-title {
    margin-bottom: var(--spacing);
  }
  .featured-promo-rail__item-description {
    width: 18rem;
    font-size: var(--meta);
  }
  .featured-promo-rail__item-link {
    margin-top: 2rem;
  }
  .featured-promo-rail__image-container {
    height: 33.2rem;
  }
}

.footer {
  align-items: center;
  background-color: var(--primaryDark);
  display: flex;
  flex-flow: column wrap;
  flex-shrink: 0;
  padding: 2rem 3.2rem 2.4rem;
  z-index: 10;
  margin-top: 15.2rem;
}
.footer__copyright {
  color: var(--white);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 3.2rem 0;
}
.footer__copyright svg {
  height: 1.6rem;
  margin: 0 0.6rem;
  width: 11rem;
}
.footer__copyright span {
  align-items: center;
  display: flex;
  font-size: var(--link);
  font-weight: var(--regular);
  line-height: 1.6rem;
  margin: 0;
  white-space: nowrap;
}
.footer__copyright span:first-child {
  margin-right: 0.4rem;
}
.footer .linked-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}
.footer .linked-list__item {
  margin-right: 1.6rem;
  list-style: none;
}
.footer .linked-list__link {
  color: var(--white);
  font-size: var(--link);
  font-weight: var(--regular);
  line-height: 1.6rem;
  white-space: nowrap;
}
.footer__social {
  display: flex;
  justify-content: flex-start;
  order: -1;
  gap: 2.4rem;
}
.footer__social-link {
  margin: 0;
}
.footer__social-link svg {
  fill: var(--white);
  height: 1.6rem;
  transition: fill 0.25s ease;
  width: 1.6rem;
}
.footer__social-link svg:hover {
  fill: var(--secondary);
}
.footer__social-link:first-child {
  margin-left: 0;
}
.footer__social-link:last-child {
  margin-right: 0;
}
.footer__cta {
  background-color: var(--primaryDark);
  display: flex;
  inset: auto 0 6.4rem;
  margin-top: 2rem;
  opacity: 0;
  order: 2;
  position: fixed;
  transform: translateY(100%);
  transition: all 0.5s;
}
.footer__cta .button {
  margin: 1.6rem auto;
  font-weight: var(--regular);
}
body[data-sticky=true] .footer__cta {
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 640px) {
  .footer {
    /* stylelint-disable-next-line selector-no-qualifying-type */
  }
  body.is-homepage .footer {
    margin-bottom: 0;
  }
}
@media (min-width: 640px) {
  .footer__copyright span:first-child {
    margin-bottom: 0;
  }
  .footer__social {
    align-items: center;
    gap: 1.6rem;
  }
  .footer__social-link {
    margin: 0;
  }
}
@media (min-width: 840px) {
  .footer {
    padding-left: 4.4rem;
    padding-right: 4.4rem;
  }
}
@media (min-width: 1200px) {
  .footer {
    flex-direction: row;
    border-top: none;
  }
  .footer__copyright {
    flex-direction: row;
    margin: 0;
  }
  .footer__copyright span:first-child {
    margin: 0;
  }
  .footer .linked-list {
    margin: 0 0 0 9.8rem;
  }
  .footer__social {
    order: 1;
    margin-left: auto;
  }
}

.hotel-listing {
  background-color: var(--white, #fff);
  padding-bottom: 4.2rem;
}

.mood-hero {
  position: relative;
  min-height: 70rem;
}
@media (max-width: 840px) {
  .mood-hero {
    min-height: 50rem;
  }
}
.mood-hero::before {
  background-color: var(--neutral700);
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 60%) var(--neutral700);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s skeleton-loading ease-in-out infinite;
  content: "";
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.mood-hero__container {
  opacity: 0;
  position: relative;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
}
.mood-hero__container.is-loaded {
  opacity: 1;
}
.mood-hero__container.is-loaded .mood-hero__play-icon {
  opacity: 1;
}
.mood-hero__video-content {
  cursor: pointer;
  position: relative;
  min-height: inherit;
}
.mood-hero__thumbnail {
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
  inset: 0;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  z-index: 10;
  height: 100%;
}
.mood-hero__thumbnail::after {
  content: "";
  display: block;
  background: var(--banner-gradient);
  pointer-events: none;
  position: absolute;
  inset: 0;
  transition: opacity 0.5s ease-in-out;
}
.mood-hero__container.is-playing .mood-hero__thumbnail {
  opacity: 0;
  pointer-events: none;
}
.mood-hero__play-icon {
  background-color: var(--mood-primary);
  pointer-events: none;
  position: absolute;
  width: 7.8rem;
  height: 7.8rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}
.mood-hero__play-icon svg {
  fill: var(--mood-secondary);
  height: 4.4rem;
  width: 4.4rem;
}
.mood-hero__container.is-playing .mood-hero__play-icon {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 840px) {
  .mood-hero__play-icon {
    width: 6.5rem;
    height: 6.5rem;
  }
  .mood-hero__play-icon svg {
    width: 3.6rem;
    height: 3.6rem;
  }
}
.mood-hero__brand-icon {
  position: absolute;
  pointer-events: none;
  max-width: 32rem;
  max-height: 18rem;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  border-radius: 50%;
  margin-bottom: 6rem;
  transition: opacity 0.5s ease-in-out;
}
.mood-hero__container.is-playing .mood-hero__brand-icon {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 840px) {
  .mood-hero__brand-icon {
    margin-bottom: 5.4rem;
    max-width: 18rem;
    max-height: 10rem;
  }
}
.mood-hero__brand-icon img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.mood-hero__video {
  min-height: 70rem;
}
.mood-hero__video .video-js {
  min-height: 70rem;
  width: 100%;
  height: 100%;
}
@media (max-width: 840px) {
  .mood-hero__video .video-js {
    min-height: 50rem;
  }
}
.mood-hero__video .vjs-title-bar {
  display: none;
}
.mood-hero__video .vjs-big-play-button {
  opacity: 0;
}
@media (max-width: 840px) {
  .mood-hero__video {
    min-height: 50rem;
  }
  .mood-hero__video .vjs-big-play-button {
    display: block;
    border-radius: 0;
    height: 100%;
    width: 100%;
    transform: none;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .mood-hero__container.is-playing .mood-hero__video .vjs-big-play-button {
    height: calc(100% - 60px);
  }
}
.mood-hero__amenities-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  overflow: auto;
  gap: 1.5rem;
  background-color: var(--mood-primary);
  color: var(--mood-secondary);
  padding: 3rem;
}
.mood-hero__amenities-bar::-webkit-scrollbar {
  height: 0.6rem;
}
.mood-hero__amenities-bar::-webkit-scrollbar-thumb {
  background-color: var(--mood-secondary);
}
.mood-hero__amenities-bar::-webkit-scrollbar-track, .mood-hero__amenities-bar::-webkit-scrollbar-thumb {
  background: transparent;
}
@media (max-width: 840px) {
  .mood-hero__amenities-bar {
    justify-content: flex-start;
    padding: 2.4rem 1.6rem;
  }
}
.mood-hero__amenity {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 10rem;
  text-align: center;
  font-size: 1.4rem;
}
.mood-hero__amenity svg {
  fill: var(--mood-secondary);
  width: 2.8rem;
  height: 2.8rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 840px) {
  .mood-hero__amenity {
    min-width: 10rem;
    font-size: 1.2rem;
  }
  .mood-hero__amenity svg {
    width: 2rem;
    height: 2rem;
  }
}

.mood-switch-dial {
  --stroke-length: 2 * calc(pi * 200);
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 4.8rem;
}
.mood-switch-dial__cover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 22.63%, rgba(0, 0, 0, 0) 47.31%, rgba(0, 0, 0, 0.4) 74.97%);
  inset: 0;
  position: fixed;
  transition: opacity 1s;
  z-index: -1;
}
.mood-switch-dial__cover img {
  mix-blend-mode: multiply;
  opacity: 0.8;
}
.mood-switch-dial__cover::after {
  background: rgba(0, 0, 0, 0.05);
  content: "";
  inset: 0;
  position: absolute;
}
.mood-switch-dial__circle {
  fill: transparent;
  height: 100%;
  opacity: 0.4;
  overflow: visible;
  stroke: var(--white);
  stroke-dasharray: var(--stroke-length) var(--stroke-length);
  stroke-width: 0.2rem;
  transition: 0.5s;
  width: 100%;
}
.mood-switch-dial__circle circle {
  transition: all 1s;
}
.mood-switch-dial__container {
  align-items: center;
  backface-visibility: hidden;
  display: flex;
  height: calc(var(--radius-mobile) * 2);
  justify-content: center;
  margin: 2rem 0;
  padding: 0;
  position: relative;
  transform: rotate(-126deg);
  width: calc(var(--radius-mobile) * 2);
  z-index: 40;
}
.mood-switch-dial__mood-logo {
  height: 8.4rem;
  position: absolute;
  pointer-events: none;
  transform: rotate(126deg);
  width: 15rem;
  z-index: 20;
}
.mood-switch-dial__dot {
  all: unset;
  background: var(--white);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  height: 2rem;
  transform: scale(0.4);
  transition: transform 0.2s cubic-bezier(0.72, 0.17, 0.68, 1.46), background-color 0.7s;
  width: 2rem;
  z-index: 20;
}
.mood-switch-dial__dot:nth-of-type(1) {
  left: 25.3307464184rem;
  top: 22.1228861582rem;
}
.mood-switch-dial__dot:nth-of-type(1).showing {
  transition-delay: 0s;
}
.mood-switch-dial__dot:nth-of-type(2) {
  left: 18.0807464184rem;
  top: 27.3903194863rem;
}
.mood-switch-dial__dot:nth-of-type(2).showing {
  transition-delay: 0.1s;
}
.mood-switch-dial__dot:nth-of-type(3) {
  left: 9.1192535816rem;
  top: 27.3903194863rem;
}
.mood-switch-dial__dot:nth-of-type(3).showing {
  transition-delay: 0.2s;
}
.mood-switch-dial__dot:nth-of-type(4) {
  left: 1.8692535816rem;
  top: 22.1228861582rem;
}
.mood-switch-dial__dot:nth-of-type(4).showing {
  transition-delay: 0.3s;
}
.mood-switch-dial__dot:nth-of-type(5) {
  left: -0.9rem;
  top: 13.6rem;
}
.mood-switch-dial__dot:nth-of-type(5).showing {
  transition-delay: 0.4s;
}
.mood-switch-dial__dot:nth-of-type(6) {
  left: 1.8692535816rem;
  top: 5.0771138418rem;
}
.mood-switch-dial__dot:nth-of-type(6).showing {
  transition-delay: 0.5s;
}
.mood-switch-dial__dot:nth-of-type(7) {
  left: 9.1192535816rem;
  top: -0.1903194863rem;
}
.mood-switch-dial__dot:nth-of-type(7).showing {
  transition-delay: 0.6s;
}
.mood-switch-dial__dot:nth-of-type(8) {
  left: 18.0807464184rem;
  top: -0.1903194863rem;
}
.mood-switch-dial__dot:nth-of-type(8).showing {
  transition-delay: 0.7s;
}
.mood-switch-dial__dot:nth-of-type(9) {
  left: 25.3307464184rem;
  top: 5.0771138418rem;
}
.mood-switch-dial__dot:nth-of-type(9).showing {
  transition-delay: 0.8s;
}
.mood-switch-dial__dot:nth-of-type(10) {
  left: 28.1rem;
  top: 13.6rem;
}
.mood-switch-dial__dot:nth-of-type(10).showing {
  transition-delay: 0.9s;
}
.mood-switch-dial__dot:focus {
  transform: scale(1);
}
.mood-switch-dial__dot:focus .mood-switch-dial__tooltip {
  opacity: 1;
}
@media (min-width: 840px) {
  .mood-switch-dial__dot:hover {
    transform: scale(1);
  }
  .mood-switch-dial__dot:hover .mood-switch-dial__tooltip {
    opacity: 1;
  }
}
.mood-switch-dial__dot.is-active {
  box-shadow: 0 0 9px 9px var(--tertiary);
  transform: scale(1);
}
.mood-switch-dial__dot.intro-animation {
  animation: dot-mobile 1s ease-in forwards;
  pointer-events: none;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(1) {
  animation-delay: 0s;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(2) {
  animation-delay: 1s;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(3) {
  animation-delay: 2s;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(4) {
  animation-delay: 3s;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(5) {
  animation-delay: 4s;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(6) {
  animation-delay: 5s;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(7) {
  animation-delay: 6s;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(8) {
  animation-delay: 7s;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(9) {
  animation-delay: 8s;
}
.mood-switch-dial__dot.intro-animation:nth-of-type(10) {
  animation-delay: 9s;
}
.mood-switch-dial__tooltip {
  display: none;
}
.mood-switch-dial__intro {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 100%;
  display: flex;
  height: 25rem;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  text-align: center;
  transform: rotate(126deg);
  width: 25rem;
}
.mood-switch-dial__intro.intro-animation .mood-switch-dial__intro-text {
  animation: video-intro 2s ease-in forwards;
}
.mood-switch-dial__intro.intro-animation .mood-switch-dial__intro-text:nth-child(1) {
  max-width: 11rem;
}
.mood-switch-dial__intro.intro-animation .mood-switch-dial__intro-text:nth-child(2) {
  animation-duration: 1.5s;
  animation-delay: 2s;
}
.mood-switch-dial__intro.intro-animation .mood-switch-dial__intro-text:nth-child(3) {
  animation-duration: 1.5s;
  animation-delay: 3.5s;
}
.mood-switch-dial__intro.intro-animation .mood-switch-dial__intro-text:nth-child(4) {
  animation-duration: 1.5s;
  animation-delay: 5s;
}
.mood-switch-dial__intro.intro-animation .mood-switch-dial__intro-text:nth-child(5) {
  animation-duration: 5s;
  animation-delay: 6.5s;
}
.mood-switch-dial__intro-text {
  display: flex;
  flex-direction: column;
  font-family: ivyjournal, sans-serif;
  font-size: 2.4rem;
  line-height: 1;
  max-width: 22.4rem;
  opacity: 0;
  position: absolute;
  transform: scale(0.95);
}
.mood-switch-dial__intro-text svg {
  margin: 1rem auto;
  width: 9.9rem;
}
.mood-switch-dial__skip-button {
  margin: 1.9rem 0 4.8rem;
  padding: 1.2em 1.6rem;
  font-size: var(--meta);
  font-weight: var(--bold);
  line-height: 1.7;
  text-transform: uppercase;
}
.mood-switch-dial__player {
  border-radius: 100%;
  box-shadow: 0 10px 10px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  height: 25rem;
  overflow: hidden;
  position: absolute;
  transform: rotate(126deg);
  width: 25rem;
  z-index: 10;
}
.mood-switch-dial__player .vjs-container {
  inset: 0;
  position: absolute;
}
.mood-switch-dial__player .vjs-container::after {
  background: rgba(0, 0, 0, 0.6);
  content: "";
  inset: 0;
  position: absolute;
}
.mood-switch-dial__player .vjs-container .video-js {
  height: 100%;
  width: 100%;
  position: relative;
}
.mood-switch-dial__player .vjs-container .video-js:hover .vjs-big-play-button {
  background-color: var(--button);
}
.mood-switch-dial__player .vjs-container .video-js .vjs-tech {
  object-fit: cover;
}
.mood-switch-dial__player .vjs-container .vjs-dock-text,
.mood-switch-dial__player .vjs-container .vjs-title-bar,
.mood-switch-dial__player .vjs-container .vjs-overlay {
  display: none;
}
.mood-switch-dial__player-control {
  background: none;
  cursor: pointer;
  height: 2.4rem;
  margin: auto auto 1.6rem;
  padding: 0;
  pointer-events: all;
  position: relative;
  width: 2.4rem;
  z-index: 20;
}
.mood-switch-dial__player-control svg {
  fill: var(--white);
  height: 2.4rem;
  width: 2.4rem;
}
.mood-switch-dial__amenities {
  display: flex;
  justify-content: space-around;
  margin: 2.4rem 0 1.6rem;
  max-width: 65.2rem;
  padding: 0 1.2rem;
  width: 100%;
}
.mood-switch-dial__amenity {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: var(--link);
  width: 33.3333333333%;
}
.mood-switch-dial__amenity svg {
  flex-shrink: 0;
  height: 2rem;
  margin-bottom: 0.4rem;
  transition: all 1s;
  width: 2rem;
}
.mood-switch-dial__cta-button {
  all: unset;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 5rem;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-weight: var(--regular);
  justify-content: center;
  line-height: 1;
  min-width: 22rem;
  opacity: 0;
  padding: 2rem 0;
  position: relative;
}
.mood-switch-dial__cta-button:focus {
  text-decoration: none;
}
@media (min-width: 840px) {
  .mood-switch-dial__cta-button:hover {
    text-decoration: none;
  }
}
.mood-switch-dial__cta-icon {
  fill: none;
  inset: 0;
  position: absolute;
  stroke: var(--white);
  stroke-dasharray: 0 1000;
  stroke-dashoffset: -210;
}
.mood-switch-dial__description {
  font-size: var(--link);
  line-height: 1.3;
  margin-top: 2.4rem;
  opacity: 0;
  text-align: center;
  transition: opacity 0.5s;
  width: 27.8rem;
}
.mood-switch-dial__description strong {
  display: block;
  line-height: 0.7;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
}
.mood-switch-dial .animate-me {
  opacity: 0;
}
.mood-switch-dial--exiting .mood-switch-dial__description {
  transition: none;
}
.mood-switch-dial--entering .mood-switch-dial__cta-button {
  transform: translateY(4rem);
}
.mood-switch-dial--entering .mood-switch-dial__cta-icon {
  stroke-dasharray: 0 1000;
}
.mood-switch-dial--entering .mood-switch-dial__description {
  transform: translateY(2rem);
}
.mood-switch-dial--entered .mood-switch-dial__cta-button {
  transition: opacity 0.5s ease-in-out 0.5s, transform 1s ease-in-out 0.4s;
  transform: translateY(0);
}
.mood-switch-dial--entered .mood-switch-dial__cta-icon {
  stroke-dasharray: 360 0;
  transition: opacity 0.5s, stroke-dasharray 1s ease-in-out 0.5s, stroke 0.5s;
}
.mood-switch-dial--entered .mood-switch-dial__amenity {
  transition: opacity 0.5s ease-in-out 0.5s;
}
.mood-switch-dial--entered .mood-switch-dial__description {
  transition: opacity 0.5s ease-in-out 1s, transform 1s ease-in-out 0.8s;
  transform: translateY(0);
}
.mood-switch-dial--entering .animate-me {
  opacity: 0;
  transition-duration: 0.2s;
}
.mood-switch-dial--entered .animate-me {
  opacity: 1;
}
@media (min-width: 640px) {
  .mood-switch-dial__container {
    height: calc(var(--radius-desktop) * 2);
    width: calc(var(--radius-desktop) * 2);
  }
  .mood-switch-dial__tooltip {
    color: var(--white);
    font-size: var(--default);
    font-weight: var(--regular);
    background-color: var(--neutral1000);
    border-radius: 1rem;
    display: block;
    line-height: 1;
    padding: 1.6rem 2.4rem;
    pointer-events: none;
    position: absolute;
    opacity: 0;
    rotate: 126deg;
    transition: all 0.2s;
    width: max-content;
  }
  .mood-switch-dial__tooltip::after {
    content: "";
    display: inline-block;
    background-color: var(--neutral1000);
    position: absolute;
    rotate: 45deg;
    height: 1.4rem;
    width: 1.4rem;
  }
  .mood-switch-dial__tooltip--0 {
    transform: translateX(21px);
    left: 20px;
  }
  .mood-switch-dial__tooltip--0::after {
    left: 50%;
    bottom: -1.1rem;
    transform: translate(-50%);
  }
  .mood-switch-dial__tooltip--1, .mood-switch-dial__tooltip--2, .mood-switch-dial__tooltip--3 {
    transform: translate(7rem, -2rem);
    right: -2.2rem;
  }
  .mood-switch-dial__tooltip--1::after, .mood-switch-dial__tooltip--2::after, .mood-switch-dial__tooltip--3::after {
    left: -0.5rem;
    top: 1.8rem;
  }
  .mood-switch-dial__tooltip--2 {
    transform: translate(8rem, -1.3rem);
    right: -3.7rem;
  }
  .mood-switch-dial__tooltip--3 {
    transform: translate(8rem, 0);
    right: -4.3rem;
  }
  .mood-switch-dial__tooltip--4, .mood-switch-dial__tooltip--5, .mood-switch-dial__tooltip--6 {
    transform: translate(-1.8rem, 6rem);
    right: -4rem;
  }
  .mood-switch-dial__tooltip--4::after, .mood-switch-dial__tooltip--5::after, .mood-switch-dial__tooltip--6::after {
    left: 50%;
    top: 0;
    transform: translate(-50%);
  }
  .mood-switch-dial__tooltip--5 {
    transform: translate(-0.75rem, 7.6rem);
    right: -6rem;
  }
  .mood-switch-dial__tooltip--6 {
    transform: translate(-1.5rem, 6.5rem);
    right: -3.7rem;
  }
  .mood-switch-dial__tooltip--7, .mood-switch-dial__tooltip--8, .mood-switch-dial__tooltip--9 {
    transform: translate(-2.9rem, 7.7rem);
    left: 5.9rem;
  }
  .mood-switch-dial__tooltip--7::after, .mood-switch-dial__tooltip--8::after, .mood-switch-dial__tooltip--9::after {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .mood-switch-dial__tooltip--7 {
    transform: translate(-3.9rem, 8.7rem);
  }
  .mood-switch-dial__tooltip--8 {
    transform: translate(-4.6rem, 10.5rem);
    left: 6.5rem;
  }
  .mood-switch-dial__tooltip--9 {
    transform: translate(-3.5rem, 9rem);
    left: 6.7rem;
  }
  .mood-switch-dial__dot {
    transform: scale(0.7);
  }
  .mood-switch-dial__dot:nth-of-type(1) {
    left: 42.3355061656rem;
    top: 37.0480675298rem;
  }
  .mood-switch-dial__dot:nth-of-type(1).showing {
    transition-delay: 0s;
  }
  .mood-switch-dial__dot:nth-of-type(2) {
    left: 30.3855061656rem;
    top: 45.7302507395rem;
  }
  .mood-switch-dial__dot:nth-of-type(2).showing {
    transition-delay: 0.1s;
  }
  .mood-switch-dial__dot:nth-of-type(3) {
    left: 15.6144938344rem;
    top: 45.7302507395rem;
  }
  .mood-switch-dial__dot:nth-of-type(3).showing {
    transition-delay: 0.2s;
  }
  .mood-switch-dial__dot:nth-of-type(4) {
    left: 3.6644938344rem;
    top: 37.0480675298rem;
  }
  .mood-switch-dial__dot:nth-of-type(4).showing {
    transition-delay: 0.3s;
  }
  .mood-switch-dial__dot:nth-of-type(5) {
    left: -0.9rem;
    top: 23rem;
  }
  .mood-switch-dial__dot:nth-of-type(5).showing {
    transition-delay: 0.4s;
  }
  .mood-switch-dial__dot:nth-of-type(6) {
    left: 3.6644938344rem;
    top: 8.9519324702rem;
  }
  .mood-switch-dial__dot:nth-of-type(6).showing {
    transition-delay: 0.5s;
  }
  .mood-switch-dial__dot:nth-of-type(7) {
    left: 15.6144938344rem;
    top: 0.2697492605rem;
  }
  .mood-switch-dial__dot:nth-of-type(7).showing {
    transition-delay: 0.6s;
  }
  .mood-switch-dial__dot:nth-of-type(8) {
    left: 30.3855061656rem;
    top: 0.2697492605rem;
  }
  .mood-switch-dial__dot:nth-of-type(8).showing {
    transition-delay: 0.7s;
  }
  .mood-switch-dial__dot:nth-of-type(9) {
    left: 42.3355061656rem;
    top: 8.9519324702rem;
  }
  .mood-switch-dial__dot:nth-of-type(9).showing {
    transition-delay: 0.8s;
  }
  .mood-switch-dial__dot:nth-of-type(10) {
    left: 46.9rem;
    top: 23rem;
  }
  .mood-switch-dial__dot:nth-of-type(10).showing {
    transition-delay: 0.9s;
  }
  .mood-switch-dial__dot.is-active {
    box-shadow: 0 0 16px 16px var(--tertiary);
  }
  .mood-switch-dial__dot.intro-animation {
    animation: dot-desktop 1s ease-in forwards;
  }
  .mood-switch-dial__intro, .mood-switch-dial__player {
    height: 41rem;
    width: 41rem;
  }
  .mood-switch-dial__player-control {
    height: 4rem;
    margin-bottom: 2.4rem;
    width: 4rem;
  }
  .mood-switch-dial__player-control svg {
    height: 4rem;
    width: 4rem;
  }
  .mood-switch-dial__amenities {
    margin: 4.8rem 0;
  }
  .mood-switch-dial__amenity {
    font-size: var(--large);
    font-weight: var(--medium);
  }
  .mood-switch-dial__amenity svg {
    height: 3.2rem;
    margin-bottom: 0.6rem;
    width: 3.2rem;
  }
  .mood-switch-dial__cta-button {
    font-size: var(--large);
    min-width: 22.4rem;
  }
  .mood-switch-dial__description {
    font-size: var(--large);
    line-height: 1.1;
    margin-top: 4.8rem;
    width: 44.9rem;
  }
  .mood-switch-dial__description strong {
    line-height: 0.9;
    margin-bottom: 1.6rem;
  }
}

.mood-switcher {
  align-items: center;
  background-color: var(--white);
  position: fixed;
  z-index: 40;
  box-shadow: 0 0.2rem 2.6rem 0 rgba(0, 11, 38, 0.08);
  transition: all 0.3s;
}
.mood-switcher__inner {
  background: inherit;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.mood-switcher__book-now {
  background-color: var(--primaryDark);
  padding: 1.6rem;
  transition: all 0.3s;
  width: 100%;
  will-change: height;
}
.mood-switcher__book-now.hide {
  height: 0;
  padding: 0;
}
.mood-switcher__book-now .button {
  padding: 1.4rem;
  max-width: 22.7rem;
  margin: auto;
}
.mood-switcher.is-homepage .mood-switcher__item.is-visible-before, .mood-switcher.is-homepage .mood-switcher__item.is-visible-after {
  display: none;
}
.mood-switcher__button {
  all: unset;
  cursor: pointer;
  height: 2.4rem;
  padding: 2rem;
  position: absolute;
  width: 2.4rem;
  z-index: 20;
}
.mood-switcher__button svg {
  fill: var(--primaryDark);
  height: 2.4rem;
  width: 2.4rem;
}
.mood-switcher__button:first-child {
  left: 6.4rem;
}
.mood-switcher__button:last-child {
  right: 6.4rem;
}
.mood-switcher__list {
  all: unset;
  align-items: center;
  display: flex;
  position: relative;
  height: 6.4rem;
  width: 100%;
}
.mood-switcher__title, .mood-switcher__item {
  all: unset;
  color: var(--primaryDark);
  font-family: ivyjournal, sans-serif;
  inset: auto 0;
  line-height: 1.2;
  opacity: 0;
  text-align: center;
  position: absolute;
  transition: opacity 1s;
  width: 100%;
}
.mood-switcher__title.is-active, .mood-switcher__item.is-active {
  opacity: 1;
}
.mood-switcher__title.is-visible-before, .mood-switcher__title.is-visible-after, .mood-switcher__item.is-visible-before, .mood-switcher__item.is-visible-after {
  width: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--link);
  inset: auto;
  margin: 0;
  opacity: 0.3;
}
.mood-switcher__title.is-visible-before, .mood-switcher__item.is-visible-before {
  left: 1.2rem;
  text-align: left;
}
.mood-switcher__title.is-visible-after, .mood-switcher__item.is-visible-after {
  right: 1.2rem;
  text-align: right;
}
.mood-switcher__title {
  font-size: var(--link);
  opacity: 0.6;
  margin-bottom: 2rem;
}
.mood-switcher__item {
  margin-top: 2rem;
}
@media (max-width: 640px) {
  .mood-switcher.is-homepage {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0.8rem;
    box-shadow: 0 2px 26px 0 rgba(0, 11, 38, 0.08);
    backdrop-filter: blur(10px);
    justify-content: space-between;
    margin: 0 1.2rem;
    position: relative;
    width: calc(100% - 2.4rem);
  }
  .mood-switcher.is-homepage .mood-switcher__title {
    display: none;
  }
  .mood-switcher.is-homepage .mood-switcher__item {
    color: var(--white);
    margin: 0;
  }
  .mood-switcher.is-homepage .mood-switcher__button:first-child {
    left: 0;
  }
  .mood-switcher.is-homepage .mood-switcher__button:last-child {
    right: 0;
  }
  .mood-switcher.is-homepage .mood-switcher__button svg {
    fill: var(--white);
  }
}
@media (min-width: 640px) {
  .mood-switcher {
    background-color: var(--white);
    border-radius: 0;
    inset: auto 0 0;
    justify-content: center;
    margin: 0;
    position: fixed;
    width: 100%;
    z-index: 30;
  }
  .mood-switcher__button {
    position: static;
  }
  .mood-switcher__button svg {
    fill: var(--primaryDark);
  }
  .mood-switcher__list {
    width: 19.1rem;
  }
  .mood-switcher__item.is-visible-before {
    font-size: var(--default);
    left: auto;
    right: calc(100% + 6.4rem);
    width: auto;
  }
  .mood-switcher__item.is-visible-after {
    font-size: var(--default);
    left: calc(100% + 6.4rem);
    right: auto;
    width: auto;
  }
  .mood-switcher.is-intro .mood-switcher__item {
    margin-top: 0;
  }
}

.standard-navigation {
  align-items: center;
  display: flex;
  height: var(--nav-height-mobile);
  inset: 0 0 auto;
  justify-content: space-between;
  padding: 0 1.2rem;
  position: fixed;
  transition: height 0.25s ease;
  will-change: height;
  width: 100%;
  z-index: 105;
}
.standard-navigation__overlay {
  background-color: var(--primaryDark);
  height: 100%;
  width: 100%;
  position: fixed;
  inset: 0;
  z-index: 40;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
body[data-menu-is-open=true] .standard-navigation__overlay {
  opacity: 0.4;
  pointer-events: all;
}
.standard-navigation--is-fixed {
  background-color: var(--primaryDark);
}
@media (min-width: 840px) {
  .standard-navigation {
    border: none;
    height: var(--nav-height-desktop);
    padding: 0 6.4rem;
    justify-content: flex-start;
  }
}
.standard-navigation__menu-link {
  display: inline-block;
}
.standard-navigation__logo {
  height: 3.5rem;
}
@media (min-width: 840px) {
  .standard-navigation__logo {
    height: 5rem;
  }
}
.standard-navigation__title {
  color: var(--secondary);
  font-family: ivyjournal, sans-serif;
  font-weight: var(--regular);
  line-height: 1.2;
  margin: 0;
}
.standard-navigation__header {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 3.2rem 0 1.6rem;
  padding: 1.2rem 2.4rem;
}
.standard-navigation__ctas-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;
  width: 100%;
}
.standard-navigation__menu-trigger {
  all: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: var(--light);
  margin-left: auto;
  margin-right: 0.8rem;
}
.standard-navigation__house-icon {
  height: 1.8rem;
  width: 1.8rem;
  margin-right: 1rem;
  transition: stroke 0.3s;
}
.standard-navigation__close-icon {
  height: 3.2rem;
  width: 3.2rem;
}
.standard-navigation__open-icon {
  height: 2.4rem;
  margin-left: 0.4rem;
  width: 2.4rem;
}
.standard-navigation__nav {
  display: flex;
  flex-direction: column;
  max-width: 43.1rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  flex: 1;
  background: var(--primary);
  text-decoration: none;
  transition: transform 0.25s ease;
  transform: translateX(100%);
  will-change: transform;
  z-index: 105;
  overflow: auto;
}
.is-showing .standard-navigation__nav {
  transform: translateX(0);
}
.standard-navigation__nav .linked-list {
  display: block;
  max-width: 37.5rem;
  padding: 0 2.4rem;
}
.standard-navigation__nav .linked-list > .linked-list__item {
  border-bottom: 1px solid var(--neutral700);
  cursor: pointer;
  display: flex;
  margin-bottom: 0.8rem;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.standard-navigation__nav .linked-list > .linked-list__item.has-children {
  flex-direction: column;
}
.standard-navigation__nav .linked-list > .linked-list__item:last-child {
  margin-right: 0;
}
.standard-navigation__nav .linked-list > .linked-list__item:focus .linked-list__dropdown {
  opacity: 1;
  transform: scale(1);
}
.standard-navigation__nav .linked-list > .linked-list__item:focus .linked-list__dropdown-icon {
  transform: rotate(180deg);
}
@media (min-width: 840px) {
  .standard-navigation__nav .linked-list > .linked-list__item:hover .linked-list__dropdown {
    opacity: 1;
    transform: scale(1);
  }
  .standard-navigation__nav .linked-list > .linked-list__item:hover .linked-list__dropdown-icon {
    transform: rotate(180deg);
  }
}
.standard-navigation__nav .linked-list__link {
  cursor: pointer;
  color: var(--text);
  font-size: var(--default);
  font-weight: var(--regular);
  padding: 1.7rem 0;
  line-height: 1;
  text-align: left;
  transition: color 0.3s;
  width: 100%;
}
.standard-navigation__nav .linked-list__link:focus {
  color: var(--navItemHovered);
  text-decoration: none;
}
@media (min-width: 840px) {
  .standard-navigation__nav .linked-list__link:hover {
    color: var(--navItemHovered);
    text-decoration: none;
  }
}
.standard-navigation__nav .linked-list__link--home {
  font-weight: var(--medium);
  display: flex;
  align-items: center;
}
.standard-navigation__nav .linked-list__link--home:focus {
  color: var(--secondary);
}
@media (min-width: 840px) {
  .standard-navigation__nav .linked-list__link--home:hover {
    color: var(--secondary);
  }
}
@media (max-width: 840px) {
  .standard-navigation__nav .linked-list .is-active .linked-list__dropdown {
    opacity: 1;
    max-height: initial;
  }
  .standard-navigation__nav .linked-list .is-active .linked-list__dropdown-icon {
    transform: rotate(180deg);
  }
}
.standard-navigation__nav .button {
  margin: 3.2rem 2.4rem;
  order: 1;
  text-transform: none;
}
.standard-navigation__right-content {
  display: flex;
  align-items: center;
  margin-left: auto;
  position: relative;
  z-index: 20;
}
.standard-navigation__lozenge {
  align-items: center;
  display: flex;
  font-size: var(--link);
  margin-left: 0.8rem;
  padding-left: 1.2rem;
  position: relative;
  text-align: center;
}
.standard-navigation__lozenge::before {
  background-color: var(--white);
  content: "";
  height: 2.8rem;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0.1rem;
}
.standard-navigation__lozenge:hover .standard-navigation__lozenge-info {
  transform: scale(1);
}
.standard-navigation__lozenge svg {
  height: 1.6rem;
  margin-left: 0.5rem;
  width: 1.6rem;
}
@media (min-width: 840px) {
  .standard-navigation__lozenge {
    margin-left: 1.2rem;
    padding-left: 1.6rem;
  }
  .standard-navigation__lozenge::before {
    height: 4.1rem;
  }
  .standard-navigation__lozenge svg {
    height: 2rem;
    width: 2rem;
  }
}
.standard-navigation__lozenge-logo {
  height: 1.2rem;
  width: 10.4rem;
}
@media (min-width: 840px) {
  .standard-navigation__lozenge-logo {
    height: 1.7rem;
    width: 15.1rem;
  }
}
.standard-navigation__lozenge-info {
  position: fixed;
  inset: var(--nav-height-mobile) 1.2rem auto;
  padding: 1.6rem;
  background-color: var(--primary);
  border-radius: 1.2rem;
  box-shadow: 0 8px 16px 0 rgba(0, 11, 38, 0.24);
  white-space: normal;
  font-weight: var(--light);
  text-align: center;
  transform: scale(0);
  transform-origin: center 0;
  transition: 0.3s transform ease;
}
@media (min-width: 840px) {
  .standard-navigation__lozenge-info {
    inset: var(--nav-top-height) auto auto 0;
    position: absolute;
    transform-origin: 0 0;
    width: 32rem;
  }
}
.standard-navigation__cta {
  border-color: transparent;
  position: absolute;
  right: 6.4rem;
  z-index: 10;
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  transition: color 0.3s;
}
.standard-navigation__cta:focus {
  border-color: transparent;
  background-color: transparent;
  text-decoration: none;
  color: currentcolor;
}
@media (min-width: 840px) {
  .standard-navigation__cta:hover {
    border-color: transparent;
    background-color: transparent;
    text-decoration: none;
    color: currentcolor;
  }
}

/* UTILITIES
========================================================================== */
/* stylelint-disable declaration-no-important  */
[data-theme=light] .u-dark-theme {
  display: none;
}

[data-theme=dark] .u-light-theme {
  display: none;
}

.u-hide {
  display: none !important;
}

.u-show {
  display: block !important;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-visually-hidden {
  visibility: hidden;
}

.u-observed {
  min-height: 1px;
  min-width: 1px;
}

.u-screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.u-no-font {
  font-size: 0;
}

.u-text-upper {
  text-transform: uppercase !important;
}

@media (max-width: 1440px) {
  .u-hide-wide {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .u-hide-desktop {
    display: none;
  }
}
@media (min-width: 1200px) {
  .u-show-desktop {
    display: none;
  }
}
@media (max-width: 1024px) {
  .u-hide-desktop-small {
    display: none;
  }
}
@media (min-width: 1024px) {
  .u-show-desktop-small {
    display: none;
  }
}
@media (max-width: 840px) {
  .u-hide-tablet {
    display: none !important;
  }
}
@media (min-width: 840px) {
  .u-show-tablet {
    display: none !important;
  }
}
@media (max-width: 640px) {
  .u-hide-phablet {
    display: none !important;
  }
}
@media (min-width: 640px) {
  .u-show-phablet {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .u-hide-desktop {
    display: none !important;
  }
}
@media (min-width: 425px) {
  .u-show-mobile {
    display: none;
  }
}
@media (max-width: 425px) {
  .u-hide-mobile {
    display: none;
  }
}
@media (min-width: 840px) {
  .u-show-until-tablet {
    display: none;
  }
}
/* stylelint-enable */
/* VENDORS
========================================================================== */
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker-popper {
  z-index: 1;
  line-height: 0;
}

.react-datepicker-popper .react-datepicker__triangle {
  stroke: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  fill: #fff;
  color: #fff;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + 0.85rem);
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:not(.react-datepicker__week-number--selected,
.react-datepicker__week-number--keyboard-selected):hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__week-number--selected {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__week-number--selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__week-number--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__week-number--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
}

.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--holidays,
.react-datepicker__month-text--holidays,
.react-datepicker__quarter-text--holidays,
.react-datepicker__year-text--holidays {
  position: relative;
  border-radius: 0.3rem;
  background-color: #ff6803;
  color: #fff;
}

.react-datepicker__day--holidays .overlay,
.react-datepicker__month-text--holidays .overlay,
.react-datepicker__quarter-text--holidays .overlay,
.react-datepicker__year-text--holidays .overlay {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.react-datepicker__day--holidays:hover,
.react-datepicker__month-text--holidays:hover,
.react-datepicker__quarter-text--holidays:hover,
.react-datepicker__year-text--holidays:hover {
  background-color: #cf5300;
}

.react-datepicker__day--holidays:hover .overlay,
.react-datepicker__month-text--holidays:hover .overlay,
.react-datepicker__quarter-text--holidays:hover .overlay,
.react-datepicker__year-text--holidays:hover .overlay {
  visibility: visible;
  opacity: 1;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__day--disabled .overlay,
.react-datepicker__month-text--disabled .overlay,
.react-datepicker__quarter-text--disabled .overlay,
.react-datepicker__year-text--disabled .overlay {
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__close-icon--disabled {
  cursor: default;
}

.react-datepicker__close-icon--disabled::after {
  cursor: default;
  background-color: #ccc;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}