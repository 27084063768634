.button {
    align-items: center;
    background-color: var(--button);
    border-radius: 5rem;

    /* so we can animate on hover */
    border: 0.1rem solid transparent;
    color: var(--white);
    cursor: pointer;
    display: flex;
    font-size: var(--default);
    font-weight: var(--bold);
    justify-content: center;
    line-height: 1;
    padding: 1.1rem 1.4rem;
    transition:
        background-color 0.3s,
        border-color 0.3s,
        color 0.3s;

    svg {
        fill: currentcolor;
        flex-shrink: 0;
    }

    @include hover {
        border-color: var(--button);
        background-color: var(--accentBlueDark);
        color: var(--button);
        text-decoration: none;

        svg {
            fill: currentcolor;
        }
    }

    &--transparent {
        background-color: transparent;
        border-color: var(--neutral800);
        border-radius: 5rem;
        color: var(--neutral800);
        font-weight: var(--regular);
        min-width: 22rem;
        padding: 2rem 3.2rem;

        @include hover {
            border-color: currentcolor;
            background-color: transparent;
            color: currentcolor;
        }

        @media (min-width: $break-tablet) {
            font-size: var(--large);
            min-width: 22.4rem;
            padding: 1.8rem 3.2rem;
        }
    }

    &--bold {
        font-weight: $bold;

        @include hover {
            color: var(--neutral800);
        }
    }

    &--white {
        border: .138rem solid var(--white);
        text-transform: uppercase;
        color: var(--white);

        &:hover {
            background-color: transparent;
            color: currentcolor;
            border-color: currentcolor;
        }
    }
}