.mood-hero {
    $this: &;
    position: relative;
    min-height: 70rem;

    @media (max-width: $break-tablet) {
        min-height: 50rem;
    }

    &::before {
        @include skeleton-background;
        content: '';
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &__container {
        opacity: 0;
        position: relative;
        transition: opacity 0.5s ease-in-out;
        width: 100%;

        &.is-loaded {
            opacity: 1;

            #{$this}__play-icon {
                opacity: 1;
            }
        }
    }

    &__video-content {
        cursor: pointer;
        position: relative;
        min-height: inherit;
    }

    &__thumbnail {
        pointer-events: none;
        background: rgb(0 0 0 / 40%);
        inset: 0;
        position: absolute;
        transition: opacity 0.5s ease-in-out;
        z-index: 10;
        height: 100%;

        &::after {
            content: '';
            display: block;
            background: var(--banner-gradient);
            pointer-events: none;
            position: absolute;
            inset: 0;
            transition: opacity 0.5s ease-in-out;
        }

        #{$this}__container.is-playing & {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__play-icon {
        background-color: var(--mood-primary);
        pointer-events: none;
        position: absolute;
        width: 7.8rem;
        height: 7.8rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.5s ease-in-out;
        opacity: 0; // becomes visible on .is-loaded

        svg {
            fill: var(--mood-secondary);
            height: 4.4rem;
            width: 4.4rem;
        }

        #{$this}__container.is-playing & {
            opacity: 0;
            pointer-events: none;
        }

        @media (max-width: $break-tablet) {
            width: 6.5rem;
            height: 6.5rem;

            svg {
                width: 3.6rem;
                height: 3.6rem;
            }
        }
    }

    &__brand-icon {
        position: absolute;
        pointer-events: none;
        max-width: 32rem;
        max-height: 18rem;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        border-radius: 50%;
        margin-bottom: 6rem;
        transition: opacity 0.5s ease-in-out;

        #{$this}__container.is-playing & {
            opacity: 0;
            pointer-events: none;
        }

        @media (max-width: $break-tablet) {
            margin-bottom: 5.4rem;
            max-width: 18rem;
            max-height: 10rem;
        }

        img {
            width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    // Video Hero Styles
    &__video {
        min-height: 70rem;

        .video-js {
            min-height: 70rem;
            width: 100%;
            height: 100%;

            @media (max-width: $break-tablet) {
                min-height: 50rem;
            }
        }

        .vjs-title-bar {
            display: none;
        }

        .vjs-big-play-button {
            opacity: 0;
        }

        @media (max-width: $break-tablet) {
            min-height: 50rem;

            .vjs-big-play-button {
                display: block;
                border-radius: 0;
                height: 100%;
                width: 100%;
                transform: none;
                top: 0;
                left: 0;
                z-index: 10;
    
                #{$this}__container.is-playing & { 
                    // 60 = control bar + progress bar heights
                    height: calc(100% - 60px);
    
                }
            }
        }
    }

    &__amenities-bar {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        overflow: auto;
        gap: 1.5rem;
        background-color: var(--mood-primary);
        color: var(--mood-secondary);
        padding: 3rem;

        &::-webkit-scrollbar {
            height: 0.6rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--mood-secondary);
        }

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

        @media (max-width: $break-tablet) {
            justify-content: flex-start;
            padding: 2.4rem 1.6rem;
        }
    }

    &__amenity {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 10rem;
        text-align: center;
        font-size: 1.4rem;

        svg {
            fill: var(--mood-secondary);
            width: 2.8rem;
            height: 2.8rem;
            margin-bottom: 0.5rem;
        }

        @media (max-width: $break-tablet) {
            min-width: 10rem;
            font-size: 1.2rem;

            svg {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}