.standard-navigation {
    $this: &;
    align-items: center;
    display: flex;
    height: var(--nav-height-mobile);
    inset: 0 0 auto;
    justify-content: space-between;
    padding: 0 1.2rem;
    position: fixed;
    transition: height 0.25s ease;
    will-change: height;
    width: 100%;

    // higher then the sticky bar
    z-index: 105;

    &__overlay {
        background-color: var(--primaryDark);
        height: 100%;
        width: 100%;
        position: fixed;
        inset: 0;
        z-index: $level-four;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s;

        body[data-menu-is-open="true"] & { 
            opacity: .4;
            pointer-events: all;
        }
    }

    &--is-fixed {
        background-color: var(--primaryDark);
    }

    @media (min-width: $break-tablet) {
        border: none;
        height: var(--nav-height-desktop);
        padding: 0 6.4rem;
        justify-content: flex-start;
    }

    &__menu-link {
        display: inline-block;
    }

    &__logo {
        height: 3.5rem;

        @media (min-width: $break-tablet) {
            height: 5rem;
        }
    }

    &__title {
        color: var(--secondary);
        font-family: ivyjournal, sans-serif;
        font-weight: var(--regular);
        line-height: 1.2;
        margin: 0;
    }

    &__header {
        align-items: flex-start;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 3.2rem 0 1.6rem;
        padding: 1.2rem 2.4rem;
    }

    &__ctas-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 1.4rem;
        width: 100%;
    }

    &__menu-trigger {
        all: unset;
        align-items: center;
        cursor: pointer;
        display: flex;
        font-weight: var(--light);
        margin-left: auto;
        margin-right: 0.8rem;
    }

    &__house-icon {
        height: 1.8rem;
        width: 1.8rem;
        margin-right: 1rem;
        transition: stroke .3s;
    }

    &__close-icon {
        height: 3.2rem;
        width: 3.2rem;
    }

    &__open-icon {
        height: 2.4rem;
        margin-left: 0.4rem;
        width: 2.4rem;
    }

    &__nav {
        display: flex;
        flex-direction: column;
        max-width: 43.1rem;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100dvh;
        flex: 1;
        background: var(--primary);
        text-decoration: none;
        transition: transform 0.25s ease;
        transform: translateX(100%);
        will-change: transform;
        z-index: 105;
        overflow: auto;

        .is-showing & {
            transform: translateX(0);
        }

        .linked-list {
            display: block;
            max-width: 37.5rem;
            padding: 0 2.4rem;

            >.linked-list__item {
                border-bottom: 1px solid var(--neutral700);
                cursor: pointer;
                display: flex;
                margin-bottom: 0.8rem;
                width: 100%;
                justify-content: space-between;
                position: relative;

                &.has-children {
                    flex-direction: column;
                }

                &:last-child {
                    margin-right: 0;
                }

                @include hover {
                    .linked-list {
                        &__dropdown {
                            opacity: 1;
                            transform: scale(1);
                        }

                        &__dropdown-icon {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            &__link {
                cursor: pointer;
                color: var(--text);
                font-size: var(--default);
                font-weight: var(--regular);
                padding: 1.7rem 0;
                line-height: 1;
                text-align: left;
                transition: color .3s;
                width: 100%;

                @include hover {
                    color: var(--navItemHovered);
                    text-decoration: none;
                }

                &--home {
                    font-weight: var(--medium);
                    display: flex;
                    align-items: center;

                    @include hover {
                        color: var(--secondary);
                    }
                }
            }

            @media (max-width: $break-tablet) {
                .is-active {
                    .linked-list__dropdown {
                        opacity: 1;
                        max-height: initial;
                    }

                    .linked-list__dropdown-icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .button {
            margin: 3.2rem 2.4rem;
            order: 1;
            text-transform: none;
        }
    }

    &__right-content {
        display: flex;
        align-items: center;
        margin-left: auto;
        position: relative;
        z-index: $level-two;
    }

    &__lozenge {
        align-items: center;
        display: flex;
        font-size: var(--link);
        margin-left: 0.8rem;
        padding-left: 1.2rem;
        position: relative;
        text-align: center;

        &::before {
            background-color: var(--white);
            content: '';
            height: 2.8rem;
            left: 0;
            opacity: 0.5;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 0.1rem;
        }

        &:hover {
            #{$this}__lozenge-info {
                transform: scale(1);
            }
        }

        svg {
            height: 1.6rem;
            margin-left: 0.5rem;
            width: 1.6rem;
        }

        @media (min-width: $break-tablet) {
            margin-left: 1.2rem;
            padding-left: 1.6rem;

            &::before {
                height: 4.1rem;
            }

            svg {
                height: 2rem;
                width: 2rem;
            }
        }
    }

    &__lozenge-logo {
        height: 1.2rem;
        width: 10.4rem;

        @media (min-width: $break-tablet) {
            height: 1.7rem;
            width: 15.1rem;
        }
    }

    &__lozenge-info {
        position: fixed;
        inset: var(--nav-height-mobile) 1.2rem auto;
        padding: 1.6rem;
        background-color: var(--primary);
        border-radius: 1.2rem;
        box-shadow: 0 8px 16px 0 rgb(0 11 38 / 24%);
        white-space: normal;
        font-weight: var(--light);
        text-align: center;
        transform: scale(0);
        transform-origin: center 0;
        transition: 0.3s transform ease;

        @media (min-width: $break-tablet) {
            inset: var(--nav-top-height) auto auto 0;
            position: absolute;
            transform-origin: 0 0;
            width: 32rem;
        }
    }

    &__cta {
        border-color: transparent;
        position: absolute;
        right: 6.4rem;
        z-index: $level-one;
        color: var(--white);
        font-weight: $medium;
        text-transform: uppercase;
        transition: color .3s;

        @include hover {
            border-color: transparent;
            background-color: transparent;
            text-decoration: none;
            color: currentcolor;
        }
    }
}