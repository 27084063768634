// Styles for: animations
// Notes: animation style for all devices
@keyframes skeleton-loading {
    to {
        background-position-x: -20%;
    }
}

@keyframes slide-in {
    0% {
        transform: translate(100%);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes video-intro {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes dot-mobile {
    0% {
        transform: scale(0.4);
    }

    50% {
        box-shadow: 0 0 9px 9px var(--tertiary);
        transform: scale(1);
    }

    100% {
        transform: scale(0.4);
    }
}

@keyframes dot-desktop {
    0% {
        transform: scale(0.7);
    }

    50% {
        box-shadow: 0 0 9px 9px var(--tertiary);
        transform: scale(1);
    }

    100% {
        transform: scale(0.7);
    }
}
