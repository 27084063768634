/*
 * Adds hover and focus styles for tablet (by default) and up
 * Only keeps focus styles for below tablet (by default)
 * If focus set to false then only hover styling will be applied
 */

@mixin hover($breakpoint: $break-tablet, $focus: true) {
    @if $focus == true {
        &:focus {
            @content;
        }
    }

    @media (min-width: $breakpoint) {
        &:hover {
            @content;
        }
    }
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
  https://github.com/bfred-it/object-fit-images
*/

@mixin object-fit($fit: fill, $position: null) {
    // stylelint-disable-next-line property-no-vendor-prefix
    -o-object-fit: $fit;
    object-fit: $fit;

    @if $position {
        // stylelint-disable-next-line property-no-vendor-prefix
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
}

/**
 * This mixin can be used to define a line clamp limit
 * @type {Number}
 */

@mixin line-clamp($lines: 2) {
    @supports (-webkit-line-clamp: $lines) {
        // stylelint-disable-next-line value-no-vendor-prefix
        display: -webkit-box;
        // stylelint-disable-next-line property-no-vendor-prefix
        -webkit-box-orient: vertical;

        /* autoprefixer: on */
        -webkit-line-clamp: $lines; // truncates the text after two lines
        overflow: hidden;
    }
}

/**
 * This mixin can be used to add skeleton loading background
 */
@mixin skeleton-background() {
    background-color: var(--neutral700);
    background: linear-gradient(
            100deg,
            rgb(255 255 255 / 0%) 40%,
            rgb(255 255 255 / 30%) 50%,
            rgb(255 255 255 / 0%) 60%
        )
        var(--neutral700);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1.5s skeleton-loading ease-in-out infinite;
}

/*
 * Applies an ellipsis to text that overflows the width passed into the mixin
 * NB this ONLY works on single lines of text
 */

@mixin truncate($width: 100%) {
    width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin dots($radius) {
    $num: 10;
    $ba: math.div(360deg, $num);

    @for $i from 1 through $num {
        $a: ($i) * $ba;
        $x: ( $radius - 0.1rem) * cos($a);
        $y: ( $radius - 0.1rem) * sin($a);

        &:nth-of-type(#{$i}) {
            left: $x + $radius - 1rem;
            top: $y + $radius - 1rem;

            &.showing {
                transition-delay: ($i - 1) * 0.1s;
            }
        }
    }
}