html {
    max-width: 100%;
    font-size: 62.5%;
    height: 100%;
}

body {
    --white: #fff;
    --black: #000;
    --logoText: #fff;
    --neutral50: #f8f5f4;
    --neutral100: #f0ebe8;
    --neutral200: #d7d4d2;
    --neutral300: #b9b6b8;
    --neutral400: #94949e;
    --neutral500: #727483;
    --neutral600: #575b6e;
    --neutral700: #343b53;
    --neutral800: #202843;
    --neutral900: #141d38;
    --neutral1000: #000b26;
    --neutral900light: #141d38;
    --accentRed: #eb5433;
    --accentRedDark: #ca2e2b;
    --accentGreen: #0d7066;
    --accentGreenDark: #075758;
    --accentBlue: #2950b8;
    --accentBlueLight: #1668e3; // new
    --accentBlueDark: #0d4eaf; // new
    --rgbaNeutral800: 32, 40, 67;
    --rgbaNeutral900: 22, 26, 39;
    --primary: var(--neutral800);
    --primaryDark: var(--neutral1000);
    --primaryDarker: var(--neutral900);
    --secondary: #fddb32;
    --secondaryDark: #ffb64c;
    --secondaryDarker: #daa52c;
    --tertiary: #56dec3;
    --text: var(--neutral50);
    --lightText: #f8f5f4;

    // projet with the initial button color in blue
    --button: var(--accentBlueLight);
    --buttonDisabled: var(--neutral400);
    --buttonHovered: var(--secondaryDarker);
    --navItemHovered: #9A9DAC;
    --socialButton: var(--lightText);
    --imageBackground: #161a27;
    --sliderBar: #ffc94c;
    --seperator-line: #dad6d6;
    --vjsSelectedText: #202843;
    --navigationTopBackground: #202843;
    --yellow: #ffc94c;
    --yellowDark: #ffba19;
    --rgbaSecondary: 255, 201, 76;
    --light: 300;
    --regular: 400;
    --medium: 500;
    --bold: 700;
    --extra-bold: 800;
    --aspect16-10: 62.5%; // 16:10
    --aspect16-9: 56.25%; // 16:9
    --aspect1-1: 100%; // 1:1
    --widget-width: 142.4rem;
    --widget-width-narrow: 71.2rem;
    --widget-padding-mobile: 1.2rem;
    --widget-padding-desktop: 3.2rem;
    --nav-height-desktop-sticky: 5.2rem;
    --nav-height-desktop: 8.8rem;
    --nav-top-height: 4.8rem;
    --nav-height-mobile: 6.8rem;
    --header-height-mobile: calc(var(--nav-height-mobile) + var(--nav-top-height));
    --header-height-desktop: calc(var(--nav-height-desktop) + var(--nav-top-height));
    --radius-mobile: 14.6rem;
    --radius-desktop: 24rem;
    --colorPlaceholder: rgb(101 101 101 / 40%);
    --cardShadow: .2rem .2rem 1.2rem rgb(0 11 38 / 10%);
    --banner-gradient: linear-gradient(180deg,
            rgb(22 26 39 / 0%) 9.57%,
            rgb(22 26 39 / 90%) 100%);
    --popupGradient: linear-gradient(180deg,
            rgb(22 26 39 / 0%) 15.57%,
            rgb(22 26 39 / 88%) 63.85%,
            var(--imageBackground) 100%);
    --controlsGradientDark: linear-gradient(270deg,
            rgb(32 40 67 / 70%) 0%,
            rgb(32 40 67 / 70%) 100%);
    --controlsGradientLight: linear-gradient(270deg,
            rgb(248 245 244 / 70%) 0%,
            rgb(248 245 244 / 70%) 100%);
    --heroChannelGradientLight: linear-gradient(180deg,
            rgb(248 245 244 / 0.01%) 39.92%,
            rgb(248 245 244 / 80%) 72.52%,
            #f8f5f4 93.92%);
    --heroChannelGradientDark: linear-gradient(to bottom,
            rgb(32 40 67 / 0%) 40%,
            var(--neutral800) 100%);
    --spacing-xxs: 0.4rem;
    --spacing-xs: 0.8rem;
    --spacing-s: 1.2rem;
    --spacing-m: 1.6rem;
    --spacing-l: 2.4rem;
    --spacing-xl: 3.2rem;
    --spacing-xxl: 6.4rem;
    --spacing-xxxl: 12.8rem;
    --default: 1.6rem;
    --large: 1.8rem;
    --header1: 4rem;
    --header2: 3.2rem;
    --header3: 2.5rem;
    --header4: 2rem;
    --header5: 1.8rem;
    --header6: var(--default);
    --meta: 1.4rem;
    --key: 1.3rem;
    --link: 1.2rem;
    --small: 1.1rem;
    --tiny: 1rem;

    &[data-theme='light'] {
        --logoText: #191e3b;
        --neutral50: #202843;
        --neutral600: #d7d4d2;
        --neutral700: var(--neutral600);
        --neutral900: #f8f5f4;
        --neutral1000: var(--neutral900);
        --primary: var(--neutral900);
        --secondary: var(--neutral800);
        --button: var(--accentBlueLight);
        --buttonHovered: var(--primaryDarker);
        --socialButton: var(--button);
        --sliderBar: var(--white);
        --vjsSelectedText: var(--white);
        --mapContentCard: var(--neutral1000);
        --navigationTopBackground: #f0ebe8;
        --cardShadow: 0 2px 4px rgb(0 11 38 / 20%);
        --popup-gradient: linear-gradient(180deg,
                rgb(255 255 255 / 0%) 15.57%,
                rgb(255 255 255 / 88%) 63.85%,
                var(--neutral1000) 100%);
    }

    &[data-enlarge='true'] {
        --default: 2rem;
        --large: 2.4rem;
        --header1: 6.4rem;
        --header2: 4.8rem;
        --header3: 3.6rem;
        --header4: 2.8rem;
        --header5: 2.4rem;
        --meta: 1.8rem;
        --key: 1.6rem;
        --link: var(--meta);
        --small: 1.4rem;
        --tiny: 1.3rem;

        a,
        a * {
            text-decoration: underline !important;
        }
    }
    color: var(--text);
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-family: CentraNo2,
    helvetica,
    sans-serif;
    font-size: var(--default);
    overflow-x: hidden;

    &.is-homepage {
        background-color: var(--primaryDark);
    }
}

.body-content {
    flex: 1 0 auto;
    overflow-x: hidden;
    padding-top: var(--nav-height-mobile);

    @media (min-width: $break-tablet) {
        padding-top: var(--nav-height-desktop);
    }
}

.skeleton-background {
    @include skeleton-background;
}

button {
    color: inherit;
    cursor: pointer;
    border: none;
    background: inherit;
    font-family: inherit;
}

ul {
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

svg {
    fill: var(--text);
}

/* Remove Autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: inherit;
    box-shadow: 0 0 0 1000px white inset;
}