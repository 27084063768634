.featured-promo-rail {
    $this: &;
    position: relative;
    background-color: var(--white, #fff);
    max-width: 100%;

    +section {
        position: relative;

        &::before {
            content: '';
            width: 25%;
            display: block;
            margin: 0 auto;
            height: 0.1rem;
            background-color: var(--seperator-line);
        }
    }

    &__list-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 144rem;
        margin: 0 auto;

        @include hover {
            .featured-promo-rail-pagination {
                opacity: 1;
            }
        }
    }

    &__title {
        margin: 0;
        order: -1;
        text-align: center;
        color: var(--primary);

        // Fix for weird behaviour when the user is clicking in the navigation
        // and it highlights the title, probably because of the order: -1;
        &::selection {
            background: transparent;
        }
    }

    &__list {
        all: unset;
        display: flex;
        list-style: none;
        position: relative;
        transition: transform 0.6s ease-in-out;
        width: calc(849 / 1440 * 100%);
    }

    &__list-item {
        flex-shrink: 0;
        margin: 0 2.4rem 0 0;
        pointer-events: none;
        transition: opacity 0.25s $quick-bezier;
        width: 100%;
        opacity: 0.4;

        &:not(.is-active) {
            #{$this} {
                &__item-description,
                &__item-link {
                    opacity: 0;
                    transition: opacity .2s;
                }
            }
        }

        &.is-active {
            opacity: 1;
            pointer-events: unset;
        }
    }

    &__card {
        display: flex;
        height: 60rem;
        position: relative;
    }

    &__image-container {
        background-color: var(--neutral100);
        border-radius: 0.8rem;
        flex-shrink: 0;
        height: 100%;
        overflow: hidden;
        position: absolute;
        right: 0;
        width: calc(489 / 820 * 100%);
    }

    &__content {
        width: calc(562 / 820 * 100%);
        z-index: $level-one;
    }

    &__item-title {
        font-family: ivyjournal, sans-serif;
        font-size: 6.4rem;
        line-height: 100%;
        margin-top: 1.2rem;
        margin-bottom: var(--spacing-l);
        color: var(--primary);
        font-weight: 400;
    }

    &__item-description {
        width: 50%;
        font-size: 1.6rem;
        margin-bottom: 0;
        color: var(--primary);
    }

    &__item-link {
        width: fit-content;
        margin-top: 5.6rem;
        font-size: 1.6rem;
        padding: 1.2rem 1.6rem;
    }

    @media (max-width: $break-tablet) {
        .featured-promo-rail-pagination {
            display: flex;
            margin-left: auto;
            margin-right: initial;
            opacity: 1;
        }

        &__content {
            width: 100%;
            max-width: 30.7rem;
            z-index: $level-one;
        }

        &__pagination {
            margin-bottom: 3.2rem;
        }

        &__title {
            font-size: 2.5rem;
        }

        &__list {
            margin: 0 -0.6rem;
            padding: 0 0.6rem 1rem;
            width: 100%;
        }

        &__card {
            height: 40rem;
        }

        &__list-item {
            margin: 0 0.6rem;
            opacity: 1;

            &:first-child {
                margin-left: 0;
            }
        }

        &__item-title {
            font-size: 4rem;
        }

        &__item-description {
            width: 76%;
            padding-right: .8rem;
        }

        &__image-container {
            width: calc(169 / 359 * 100%);
        }
    }

    @media (max-width: $break-phablet) {
        &__card {
            height: auto;
        }

        &__item-title {
            margin-bottom: var(--spacing);
        }

        &__item-description {
            //uncomment below if we want to add back description lines limit
            // @include line-clamp(3); 
            width: 18rem;
            font-size: var(--meta);
        }

        &__item-link {
            margin-top: 2rem;
        }

        &__image-container {
            height: 33.2rem;
        }
    }
}