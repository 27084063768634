.widget-header {
    $this: &;

    &__subtitle {
        font-size: var(--default);
        font-weight: var(--bold);
        padding: 0 var(--widget-padding-mobile);
        margin-top: 0;
    }

    &__title {
        color: var(--text);
        padding: 0 var(--widget-padding-mobile);
        line-height: 1.2;
        margin-top: 0.2rem;
    }

    &__promo-rail {
        #{$this}__subtitle,
        #{$this}__title {
            padding: 0 var(--widget-padding-mobile);
        }
    }

    @media (min-width: $break-tablet) {
        &__promo-rail {
            #{$this}__subtitle,
            #{$this}__title {
                padding: 0 var(--widget-padding-desktop);
            }
        }
    }

    @media (min-width: $break-desktop) {
        &__subtitle {
            padding: 0;
        }

        &__title {
            margin-bottom: 1.5rem;
            padding: 0;
        }
    }
}
