.widget {
    $this: &;
    margin: 2.4rem auto 0;
    max-width: var(--widget-width);
    position: relative;
    padding: 0;

    &__list {
        display: flex;
        margin: 0.8rem -0.6rem 0;
        padding: 0 1.2rem 1rem;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        overflow: scroll;
    }

    &--narrow {
        max-width: var(--widget-width-narrow);
        overflow: hidden;
    }

    @media (min-width: $break-desktop) {
        margin-top: 4.8rem;
        padding: 0 var(--widget-padding-desktop);

        &__list {
            scroll-snap-type: none;
            transition: transform 0.6s ease-in-out;
            margin: 0 -1.2rem;
            padding: 0;
            position: relative;
            overflow: initial;
        }

        &--narrow & {
            &__list-wrapper {
                overflow: hidden;
            }

            &__list {
                margin: 0 -0.6rem;
            }
        }
    }
}
