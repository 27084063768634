// Element transition classes

.fade-in-on-load {
    opacity: 0;
    transition: opacity 0.25s $quick-bezier;

    &.is-loaded {
        opacity: 1;
    }
}
