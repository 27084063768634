// stylelint-disable font-weight-notation, declaration-no-important
// Font Mixins

/*
---- If Font is served through local files the following mixins can be used ----
*/

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$extrabold: 700;

@font-face {
    font-family: CentraNo2;
    src:
        url('../fonts/CentraNo2-Medium.woff') format('woff'),
        url('../fonts/CentraNo2-Medium.woff2') format('woff2');
    font-weight: $medium;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: CentraNo2;
    src:
        url('../fonts/CentraNo2-Extrabold.woff') format('woff'),
        url('../fonts/CentraNo2-Extrabold.woff2') format('woff2');
    font-weight: $extrabold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: CentraNo2;
    src:
        url('../fonts/CentraNo2-Book.woff') format('woff'),
        url('../fonts/CentraNo2-Book.woff2') format('woff2');
    font-weight: $regular;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: CentraNo2;
    src:
        url('../fonts/CentraNo2-Bold.woff') format('woff'),
        url('../fonts/CentraNo2-Bold.woff2') format('woff2');
    font-weight: $bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: CentraNo2;
    src:
        url('../fonts/CentraNo2-Light.woff') format('woff'),
        url('../fonts/CentraNo2-Light.woff2') format('woff2');
    font-weight: $light;
    font-style: normal;
    font-display: swap;
}
