.theme-mood {
    &-ziva {
        --mood-primary: #ae2375;
        --mood-secondary: var(--white, #fff);
    }

    &-zilara {
        --mood-primary: #8358A7;
        --mood-secondary: var(--white, #fff);
    }

    &-zoetry {
        --mood-primary: #018299;
        --mood-secondary: var(--white, #fff);
    }

    &-breathless {
        --mood-primary: #8643B2;
        --mood-secondary: var(--white, #fff);
    }

    &-dreams {
        --mood-primary: #cfac7c;
        --mood-secondary: var(--neutral900, #141d38);
    }

    &-vivid {
        --mood-primary: #1b806d;
        --mood-secondary: var(--white, #fff);
    }

    &-alua {
        --mood-primary: #00a4af;
        --mood-secondary: var(--white, #fff);
    }

    &-sunscape {
        --mood-primary: #ff8200;
        --mood-secondary: var(--neutral900, #141d38);
    }
}
