.object-fit-cover-picture {
    &__img {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        @include object-fit(cover, center);
    }
}
