.mood-switcher {
    $this: &;
    align-items: center;
    background-color: var(--white);
    position: fixed;
    z-index: $level-four;
    box-shadow: 0 .2rem 2.6rem 0 rgb(0 11 38 / 8%);
    transition: all .3s;

    &__inner {
        background: inherit;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    &__book-now {
        background-color: var(--primaryDark);
        padding: 1.6rem;
        transition: all .3s;
        width: 100%;
        will-change: height;

        &.hide {
            height: 0;
            padding: 0;
        }

        .button {
            padding: 1.4rem;
            max-width: 22.7rem;
            margin: auto;
        }
    }

    &.is-homepage {
        #{$this}__item {
            &.is-visible-before,
            &.is-visible-after {
                display: none;
            }
        }
    }

    &__button {
        all: unset;
        cursor: pointer;
        height: 2.4rem;
        padding: 2rem;
        position: absolute;
        width: 2.4rem;
        z-index: $level-two;

        svg {
            fill: var(--primaryDark);
            height: 2.4rem;
            width: 2.4rem;
        }

        &:first-child {
            left: 6.4rem;
        }

        &:last-child {
            right: 6.4rem;
        }
    }

    &__list {
        all: unset;
        align-items: center;
        display: flex;
        position: relative;
        height: 6.4rem;
        width: 100%;
    }

    &__title,
    &__item {
        all: unset;
        color: var(--primaryDark);
        font-family: ivyjournal, sans-serif;
        inset: auto 0;
        line-height: 1.2;
        opacity: 0;
        text-align: center;
        position: absolute;
        transition: opacity 1s;
        width: 100%;

        &.is-active {
            opacity: 1;
        }

        &.is-visible-before,
        &.is-visible-after {
            @include truncate(5rem);
            font-size: var(--link);
            inset: auto;
            margin: 0;
            opacity: 0.3;
        }

        &.is-visible-before {
            left: 1.2rem;
            text-align: left;
        }

        &.is-visible-after {
            right: 1.2rem;
            text-align: right;
        }
    }

    &__title {
        font-size: var(--link);
        opacity: .6;
        margin-bottom: 2rem;
    }

    &__item {
        margin-top: 2rem;
    }

    @media (max-width: $break-phablet) {
        &.is-homepage {
            background: rgb(255 255 255 / 20%);
            border-radius: 0.8rem;
            box-shadow: 0 2px 26px 0 rgb(0 11 38 / 8%);
            backdrop-filter: blur(10px);
            justify-content: space-between;
            margin: 0 1.2rem;
            position: relative;
            width: calc(100% - 2.4rem);

            #{$this} {
                &__title {
                    display: none;
                }

                &__item {
                    color: var(--white);
                    margin: 0;
                }

                &__button {
                    &:first-child {
                        left: 0;
                    }

                    &:last-child {
                        right: 0;
                    }

                    svg {
                        fill: var(--white);
                    }
                }
            }
        }
    }

    @media (min-width: $break-phablet) {
        background-color: var(--white);
        border-radius: 0;
        inset: auto 0 0;
        justify-content: center;
        margin: 0;
        position: fixed;
        width: 100%;
        z-index: $level-three;

        &__button {
            position: static;

            svg {
                fill: var(--primaryDark);
            }
        }

        &__list {
            width: 19.1rem;
        }

        &__item {
            &.is-visible-before {
                font-size: var(--default);
                left: auto;
                right: calc(100% + 6.4rem);
                width: auto;
            }

            &.is-visible-after {
                font-size: var(--default);
                left: calc(100% + 6.4rem);
                right: auto;
                width: auto;
            }
        }

        &.is-intro #{$this} {
            &__item {
                margin-top: 0;
            }
        }
    }
}