.footer {
    align-items: center;
    background-color: var(--primaryDark);
    display: flex;
    flex-flow: column wrap;
    flex-shrink: 0;
    padding: 2rem 3.2rem 2.4rem;
    z-index: $level-one;
    margin-top: 15.2rem;


    &__copyright {
        color: var(--white);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 3.2rem 0;

        svg {
            height: 1.6rem;
            margin: 0 0.6rem;
            width: 11rem;
        }

        span {
            align-items: center;
            display: flex;
            font-size: var(--link);
            font-weight: var(--regular);
            line-height: 1.6rem;
            margin: 0;
            white-space: nowrap;

            &:first-child {
                margin-right: 0.4rem;
            }
        }
    }

    .linked-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;

        &__item {
            margin-right: 1.6rem;
            list-style: none;
        }

        &__link {
            color: var(--white);
            font-size: var(--link);
            font-weight: var(--regular);
            line-height: 1.6rem;
            white-space: nowrap;
        }
    }

    &__social {
        display: flex;
        justify-content: flex-start;
        order: -1;
        gap: 2.4rem;
    }

    &__social-link {
        margin: 0;

        svg {
            fill: var(--white);
            height: 1.6rem;
            transition: fill 0.25s ease;
            width: 1.6rem;

            &:hover {
                fill: var(--secondary);
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__cta {
        background-color: var(--primaryDark);
        display: flex;
        inset: auto 0 6.4rem;
        margin-top: 2rem;
        opacity: 0;
        order: 2;
        position: fixed;
        transform: translateY(100%);
        transition: all .5s;

        .button {
            margin: 1.6rem auto;
            font-weight: var(--regular);
        }

        body[data-sticky="true"] & {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: $break-phablet) {

        /* stylelint-disable-next-line selector-no-qualifying-type */
        body.is-homepage & {
            margin-bottom: 0;
        }
    }

    @media (min-width: $break-phablet) {
        &__copyright {
            span:first-child {
                margin-bottom: 0;
            }
        }

        &__social {
            align-items: center;
            gap: 1.6rem;
        }

        &__social-link {
            margin: 0;
        }
    }

    @media (min-width: $break-tablet) {
        padding-left: 4.4rem;
        padding-right: 4.4rem;
    }

    @media (min-width: $break-desktop) {
        flex-direction: row;
        border-top: none;

        &__copyright {
            flex-direction: row;
            margin: 0;

            span:first-child {
                margin: 0;
            }
        }

        .linked-list {
            margin: 0 0 0 9.8rem;
        }

        &__social {
            order: 1;
            margin-left: auto;
        }
    }
}