.mood-switch-dial {
    $this: &;

    --stroke-length: 2 * calc(pi * 200);
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem 0 4.8rem;

    &__cover {
        background: linear-gradient(180deg,
                rgb(0 0 0 / 40%) 22.63%,
                rgb(0 0 0 / 0%) 47.31%,
                rgb(0 0 0 / 40%) 74.97%);
        inset: 0;
        position: fixed;
        transition: opacity 1s;
        z-index: -1;

        img {
            mix-blend-mode: multiply;
            opacity: .8;
        }

        &::after {
            background: rgb(0 0 0 / 5%);
            content: '';
            inset: 0;
            position: absolute;
        }
    }

    &__circle {
        fill: transparent;
        height: 100%;
        opacity: 0.4;
        overflow: visible;
        stroke: var(--white);
        stroke-dasharray: var(--stroke-length) var(--stroke-length);
        stroke-width: 0.2rem;
        transition: 0.5s;
        width: 100%;

        circle {
            transition: all 1s;
        }
    }

    &__container {
        align-items: center;
        backface-visibility: hidden;
        display: flex;
        height: calc(var(--radius-mobile) * 2);
        justify-content: center;
        margin: 2rem 0;
        padding: 0;
        position: relative;
        transform: rotate(-126deg);
        width: calc(var(--radius-mobile) * 2);
        z-index: $level-four;
    }

    &__mood-logo {
        height: 8.4rem;
        position: absolute;
        pointer-events: none;
        transform: rotate(126deg);
        width: 15rem;
        z-index: $level-two;
    }

    &__dot {
        all: unset;
        background: var(--white);
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        height: 2rem;
        transform: scale(0.4);
        transition:
            transform 0.2s cubic-bezier(0.72, 0.17, 0.68, 1.46),
            background-color 0.7s;
        width: 2rem;
        z-index: $level-two;

        @include dots(14.6rem);

        @include hover {
            transform: scale(1);

            #{$this}__tooltip {
                opacity: 1;
            }
        }
        
        &.is-active {
            box-shadow: 0 0 9px 9px var(--tertiary);
            transform: scale(1);
        }

        &.intro-animation {
            animation: dot-mobile 1s ease-in forwards;
            pointer-events: none;

            @for $i from 1 through 10 {
                &:nth-of-type(#{$i}) {
                    animation-delay: ($i - 1) * 1s;
                }
            }
        }
    }

    &__tooltip {
        display: none;
    }

    &__intro {
        align-items: center;
        background-color: rgb(255 255 255 / 5%);
        backdrop-filter: blur(10px);
        border-radius: 100%;
        display: flex;
        height: 25rem;
        justify-content: center;
        overflow: hidden;
        position: absolute;
        text-align: center;
        transform: rotate(126deg);
        width: 25rem;

        &.intro-animation {

            #{$this}__intro-text {
                animation: video-intro 2s ease-in forwards;

                &:nth-child(1) {
                    max-width: 11rem;
                }

                &:nth-child(2) {
                    animation-duration: 1.5s;
                    animation-delay: 2s;
                }

                &:nth-child(3) {
                    animation-duration: 1.5s;
                    animation-delay: 3.5s;
                }

                &:nth-child(4) {
                    animation-duration: 1.5s;
                    animation-delay: 5s;
                }

                &:nth-child(5) {
                    animation-duration: 5s;
                    animation-delay: 6.5s;
                }
            }
        }
    }

    &__intro-text {
        display: flex;
        flex-direction: column;
        font-family: ivyjournal, sans-serif;
        font-size: 2.4rem;
        line-height: 1;
        max-width: 22.4rem;
        opacity: 0;
        position: absolute;
        transform: scale(.95);

        svg {
            margin: 1rem auto;
            width: 9.9rem;
        }
    }

    &__skip-button {
        margin: 1.9rem 0 4.8rem;
        padding: 1.2em 1.6rem;
        font-size: var(--meta);
        font-weight: var(--bold);
        line-height: 1.7;
        text-transform: uppercase;
    }

    &__player {
        border-radius: 100%;
        box-shadow: 0 10px 10px 5px rgb(0 0 0 / 40%);
        display: flex;
        height: 25rem;
        overflow: hidden;
        position: absolute;
        transform: rotate(126deg);
        width: 25rem;
        z-index: $level-one;

        .vjs-container {
            inset: 0;
            position: absolute;

            &::after {
                background: rgb(0 0 0 / 60%);
                content: '';
                inset: 0;
                position: absolute;
            }

            .video-js {
                height: 100%;
                width: 100%;
                position: relative;

                &:hover .vjs-big-play-button {
                    background-color: var(--button);
                }

                .vjs-tech {
                    object-fit: cover;
                }
            }

            .vjs-dock-text,
            .vjs-title-bar,
            .vjs-overlay {
                display: none;
            }
        }
    }

    &__player-control {
        background: none;
        cursor: pointer;
        height: 2.4rem;
        margin: auto auto 1.6rem;
        padding: 0;
        pointer-events: all;
        position: relative;
        width: 2.4rem;
        z-index: $level-two;

        svg {
            fill: var(--white);
            height: 2.4rem;
            width: 2.4rem;
        }
    }

    &__amenities {
        display: flex;
        justify-content: space-around;
        margin: 2.4rem 0 1.6rem;
        max-width: 65.2rem;
        padding: 0 1.2rem;
        width: 100%;
    }

    &__amenity {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: var(--link);
        width: math.percentage(math.div(1, 3));

        svg {
            flex-shrink: 0;
            height: 2rem;
            margin-bottom: 0.4rem;
            transition: all 1s;
            width: 2rem;
        }
    }

    &__cta-button {
        all: unset;
        align-items: center;
        background-color: rgb(255 255 255 / 5%);
        backdrop-filter: blur(10px);
        border-radius: 5rem;
        color: var(--white);
        cursor: pointer;
        display: flex;
        font-weight: var(--regular);
        justify-content: center;
        line-height: 1;
        min-width: 22rem;
        opacity: 0;
        padding: 2rem 0;
        position: relative;

        @include hover {
            text-decoration: none;
        }
    }

    &__cta-icon {
        fill: none;
        inset: 0;
        position: absolute;
        stroke: var(--white);
        stroke-dasharray: 0 1000;
        stroke-dashoffset: -210;
    }

    &__description {
        font-size: var(--link);
        line-height: 1.3;
        margin-top: 2.4rem;
        opacity: 0;
        text-align: center;
        transition: opacity 0.5s;
        width: 27.8rem;

        strong {
            display: block;
            line-height: 0.7;
            margin-bottom: .8rem;
            text-transform: uppercase;
        }
    }

    .animate-me {
        opacity: 0;
    }

    &--exiting & {
        &__description {
            transition: none;
        }
    }

    &--entering & {
        &__cta-button {
            transform: translateY(4rem);
        }

        &__cta-icon {
            stroke-dasharray: 0 1000;
        }

        &__description {
            transform: translateY(2rem);
        }
    }

    &--entered & {
        &__cta-button {
            transition: opacity .5s ease-in-out .5s, transform 1s ease-in-out .4s;
            transform: translateY(0);
        }

        &__cta-icon {
            stroke-dasharray: 360 0;
            transition: opacity .5s, stroke-dasharray 1s ease-in-out .5s, stroke .5s;
        }

        &__amenity {
            transition: opacity .5s ease-in-out .5s;
        }

        &__description {
            transition: opacity .5s ease-in-out 1s, transform 1s ease-in-out .8s;
            transform: translateY(0);
        }
    }

    &--entering {
        .animate-me {
            opacity: 0;
            transition-duration: .2s;
        }
    }

    &--entered {
        .animate-me {
            opacity: 1;
        }
    }

    @media (min-width: $break-phablet) {
        &__container {
            height: calc(var(--radius-desktop) * 2);
            width: calc(var(--radius-desktop) * 2);
        }

        &__tooltip {
            color: var(--white);
            font-size: var(--default);
            font-weight: var(--regular);
            background-color: var(--neutral1000);
            border-radius: 1rem;
            display: block;
            line-height: 1;
            padding: 1.6rem 2.4rem;
            pointer-events: none;
            position: absolute;
            opacity: 0;
            rotate: 126deg; // same as the container rotation
            transition: all .2s;
            width: max-content;
    
            &::after {
                content: '';
                display: inline-block;
                background-color: var(--neutral1000);
                position: absolute;
                rotate: 45deg;
                height: 1.4rem;
                width: 1.4rem;
            }
    
            &--0 {
                transform: translateX(21px);
                left: 20px;

                &::after {
                    left: 50%;
                    bottom: -1.1rem;
                    transform: translate(-50%);
                }
            }
    
            &--1,
            &--2,
            &--3 {
                transform: translate(7rem, -2rem);
                right: -2.2rem;

                &::after {
                    left: -.5rem;
                    top: 1.8rem;
                }
            }

            &--2 {
                transform: translate(8rem, -1.3rem);
                right: -3.7rem;
            }

            &--3 {
                transform: translate(8rem, 0);
                right: -4.3rem;
            }
    
            &--4,
            &--5,
            &--6 {
                transform: translate(-1.8rem, 6rem);
                right: -4rem;

                &::after {
                    left: 50%;
                    top: 0;
                    transform: translate(-50%);
                }
            }

            &--5 {
                transform: translate(-.75rem, 7.6rem);
                right: -6rem;
            }

            &--6 {
                transform: translate(-1.5rem, 6.5rem);
                right: -3.7rem;
            }
    
            &--7,
            &--8,
            &--9 {
                transform: translate(-2.9rem, 7.7rem);
                left: 5.9rem;

                &::after {
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &--7 {
                transform: translate(-3.9rem, 8.7rem);
            }

            &--8 {
                transform: translate(-4.6rem, 10.5rem);
                left: 6.5rem;
            }

            &--9 {
                transform: translate(-3.5rem, 9rem);
                left: 6.7rem;
            }

        }

        &__dot {
            transform: scale(0.7);
            @include dots(24rem);

            &.is-active {
                box-shadow: 0 0 16px 16px var(--tertiary);
            }

            &.intro-animation {
                animation: dot-desktop 1s ease-in forwards;
            }
        }

        &__intro,
        &__player {
            height: 41rem;
            width: 41rem;
        }

        &__player-control {
            height: 4rem;
            margin-bottom: 2.4rem;
            width: 4rem;

            svg {
                height: 4rem;
                width: 4rem;
            }
        }

        &__amenities {
            margin: 4.8rem 0;
        }

        &__amenity {
            font-size: var(--large);
            font-weight: var(--medium);

            svg {
                height: 3.2rem;
                margin-bottom: 0.6rem;
                width: 3.2rem;
            }
        }

        &__cta-button {
            font-size: var(--large);
            min-width: 22.4rem;
        }

        &__description {
            font-size: var(--large);
            line-height: 1.1;
            margin-top: 4.8rem;
            width: 44.9rem;

            strong {
                line-height: 0.9;
                margin-bottom: 1.6rem;
            }
        }
    }
}