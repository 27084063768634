@use "sass:math";
/* stylelint-disable-next-line comment-empty-line-before */
/* SASS MODULES
========================================================================== */

@use 'sass:color';

/* SETTINGS
========================================================================== */

@import 'includes/settings/variables';

@import 'includes/global/mixins';

@import 'includes/global/mood-themes';

/* LAYOUT
========================================================================== */

/* GLOBAL
========================================================================== */

@import 'includes/global/fonts';

@import 'includes/global/global';

@import 'includes/global/typography';

@import 'includes/global/transitions';

@import 'includes/global/animations';

/* COMPONENTS
========================================================================== */

@import "includes/components/_brightcove-player.scss";
@import "includes/components/_button.scss";
@import "includes/components/_linked-list.scss";
@import "includes/components/_meta-data.scss";
@import "includes/components/_object-fit-cover-picture.scss";
@import "includes/components/_static-hero.scss";
@import "includes/components/_tag.scss";
@import "includes/components/_video-play-cta.scss";
@import "includes/components/_widget-header.scss";
@import "includes/components/_widget.scss";
@import "includes/components/_wrapper.scss";

/* WIDGETS
========================================================================== */

@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/404/styles/_404.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/content/styles/_content.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/cookie-banner/styles/_cookie-banner.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/featured-promo-rail/styles/_featured-promo-rail.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/footer/styles/_footer.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/hotel-listing/styles/hotel-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/mood-hero/styles/mood-hero.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/mood-switch/styles/_mood-switch-dial.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/mood-switcher/styles/_mood-switcher.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-hyatt-hotels/navigation/styles/_standard-navigation.scss";

/* UTILITIES
========================================================================== */

@import "includes/utilities/_trumps.scss";

/* VENDORS
========================================================================== */
// for Booking Widget
@import '../../node_modules/react-datepicker/dist/react-datepicker';
