.cookie-banner {
    inset: 0;
    position: fixed;
    z-index: 300;

    &__content {
        display: flex;
        align-items: flex-end;
        background-color: rgb(0 11 38 / 80%);
        box-shadow: 0 -10px 25px rgb(0 0 0 / 80%);
        inset: auto 0 0;
        max-width: calc(100vw - 5.6rem);
        padding: 4rem;
        position: fixed;
        z-index: 320;
        margin: 2.8rem auto;
        border-radius: 0.8rem;

        @media (max-width: $break-tablet) {
            margin: 0;
            max-width: 100%;
            flex-direction: column;
            align-items: center;
            text-align: center;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__content-left {
        flex: 1 1;
    }

    &__title {
        font-family: ivyjournal, sans-serif;
        font-size: 3.8rem;
        font-weight: var(--regular);
        margin: 0;
        margin-bottom: 1.6rem;

        @media (max-width: $break-tablet) {
            font-size: 2rem;
        }
    }

    &__text {
        color: var(--text);
        max-width: 70%;

        @media (max-width: $break-tablet) {
            max-width: 100%;
            font-size: 1.4rem;
        }
    }

    &__link {
        font-size: 1.6rem;
        font-weight: 700;
    }

    &__accept-button {
        padding: 1.6rem 2.8rem;
        border: 0.1rem solid var(--white);
        border-radius: 5rem;
        transition: all 0.6s ease-in-out;

        &:hover {
            background-color: var(--white);
            color: var(--neutral800);
        }
    }
}