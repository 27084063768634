.video-play-cta {
    display: flex;
    align-items: center;
    margin: 0.8rem 0;
    word-break: break-word;

    &__button {
        align-items: center;
        background-color: var(--button);
        border-radius: 100%;
        border: 0.1rem solid transparent;
        cursor: pointer;
        display: flex;
        height: 5.6rem;
        justify-content: center;
        transition:
            transform 0.5s ease-in-out,
            background-color 0.3s;
        z-index: 2;
        width: 5.6rem;

        @include hover {
            background-color: rgb(var(--rgbaNeutral800) 0.7);
            border-color: var(--button);

            svg {
                fill: var(--button);
            }
        }

        svg {
            fill: var(--primaryDark);
            height: 4rem;
            width: 4rem;
        }
    }

    &__description {
        padding-left: 1.6rem;
    }

    &__progress {
        color: var(--secondary);
        font-size: var(--meta);
        font-weight: var(--light);

        body[data-theme='light'] & {
            color: var(--white);
        }
    }

    &__title {
        color: var(--secondary);
        font-weight: var(--bold);
        line-height: 1.2;
        margin: 0;

        body[data-theme='light'] & {
            color: var(--white);
        }
    }

    @media (min-width: $break-desktop-small) {
        margin: 2.4rem 0;
    }
}
