.error-404 {
    color: var(--neutral800);
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100lvh;
    justify-content: center;
    margin-top: calc(-1 * var(--nav-height-desktop));

    &__title {
        font-family: ivyjournal, sans-serif;
        font-weight: var(--regular);
        color: var(--neutral800);
        font-size: 6.4rem;
        margin: 0;
    }

    &__description {
        color: var(--neutral800);
    }

    &__link {
        margin-top: 4.8rem;
        font-weight: $bold;
    }

    @media (max-width: $break-tablet) {
        &__title {
            font-size: 4rem;
        }

        &__link {
            margin-top: 3.2rem;
        }
    }
}