/* Breakpoints */
$break-mobile-small: 375px;
$break-mobile: 425px;
$break-phablet: 640px;
$break-tablet: 840px;
$break-desktop-small: 1024px;
$break-desktop: 1200px;
$break-desktop-medium: 1360px;
$break-wide: 1440px;
$break-fullscreen: 1520px;
$break-fullscreen-large: 2000px;

/* Z-Index Layers */
$level-one: 10;
$level-two: 20;
$level-three: 30;
$level-four: 40;
$level-five: 50;
$level-six: 60;
$level-seven: 70;

/* Transitions */
$quick-bezier: cubic-bezier(0, 0.4, 0.5, 1);
$transition: 0.25s ease;
